import { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

export const useDocId = () => {
	const [docID, setDocID] = useState<string>('')
	let match: any = useRouteMatch('/create/:type/:id')
	useEffect(() => {
		if (match && match.isExact && match.params.id) {
			setDocID(atob(match.params.id))
		} else {
			setDocID('')
		}
	}, [match])

	return docID
}
