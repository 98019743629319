import { usePostHog } from 'posthog-js/react'
import Premium from '../Premium/Premium'
import Modal from './Modal'
import './_Subscriptions.scss'
import { useEffect } from 'react'

function Subscriptions({
	open,
	closeModal,
	annualPlan,
	setAnnualPlan,
	setLoginModal,
	noMoreCredits = false,
}: {
	open: boolean
	closeModal: () => void
	annualPlan: boolean
	setAnnualPlan: (annual: boolean) => void
	setLoginModal: (open: boolean) => void
	noMoreCredits?: boolean
}) {
	const posthog = usePostHog()

	useEffect(() => {
		posthog.capture('upgrade-modal-opened')
	}, [open])

	return (
		<Modal
			open={open}
			closeModal={() => {
				closeModal()
				posthog.capture('upgrade-modal-closed')
			}}
			portalClassName="subscription"
		>
			<div className="flex-container">
				<div className="pro">
					{
						<Premium
							closeParentModal={closeModal}
							setLoginModal={setLoginModal}
							annualPlan={annualPlan}
							setAnnualPlan={setAnnualPlan}
							noMoreCredits={noMoreCredits}
						/>
					}
				</div>
			</div>
		</Modal>
	)
}

export default Subscriptions
