import { useDispatch, useSelector } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'
import { DocumentState } from '../../redux/types'
import { selectDocumentState, setIsOnSubtopicsStep, setUserWriterSelections } from '../../redux/docSlice'
import { useDocId } from '../../hooks/docID'
import './_Subtopics.scss'
import Button from '../../Components/Buttons'
import { useMemo } from 'react'
import { ChevronLeft } from '@mui/icons-material'
import { useProcessedSubtopics } from './hooks'
import { FaPlus } from 'react-icons/fa6'
import { FaRegTrashAlt } from 'react-icons/fa'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'

export const SubtopicsInput = ({ docID }: { docID: string }) => {
	const processedSubtopics = useProcessedSubtopics()
	const dispatch = useDispatch()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))

	const minNumberOfSubtopics = useMemo(() => {
		return Math.ceil((documentState.userWriterSelections.wordCount - 500) / 600)
	}, [documentState.userWriterSelections.wordCount])

	const maxNumberOfSubtopics = useMemo(() => {
		return Math.floor((documentState.userWriterSelections.wordCount - 500) / 250)
	}, [documentState.userWriterSelections.wordCount])

	return (
		<>
			{processedSubtopics.map((subtopic, index) => {
				const isIntroOrConclusion = index === 0 || index === processedSubtopics.length - 1
				return (
					<>
						<div className="flex gap-3 items-center" key={index}>
							<TextareaAutosize
								placeholder="Type a subtopic here..."
								className={`w-full py-2 px-3 my-3 text-2xl border resize-none outline-none border-gray-400 ${
									isIntroOrConclusion ? 'bg-gray-100' : ''
								}`}
								autoFocus
								value={subtopic}
								disabled={isIntroOrConclusion}
								onChange={(e) => {
									dispatch(
										setUserWriterSelections({
											docID: docID,
											userWriterSelections: {
												...documentState.userWriterSelections,
												subTopics: documentState.userWriterSelections.subTopics.map((sub, i) =>
													i === index ? e.target.value : sub
												),
											},
										})
									)
								}}
								minRows={1}
							/>
							{!isIntroOrConclusion && minNumberOfSubtopics < processedSubtopics.length - 2 && (
								<FaRegTrashAlt
									className="text-gray-500 hover:text-primary cursor-pointer"
									onClick={() => {
										dispatch(
											setUserWriterSelections({
												docID: docID,
												userWriterSelections: {
													...documentState.userWriterSelections,
													subTopics: documentState.userWriterSelections.subTopics.filter((sub, i) => i !== index),
												},
											})
										)
									}}
								/>
							)}
						</div>
						{index === processedSubtopics.length - 2 && processedSubtopics.length - 2 < maxNumberOfSubtopics && (
							<div
								className="flex justify-center items-center gap-2 border border-dotted border-gray-600 py-2 hover:bg-gray-100 hover:border-solid cursor-pointer text-gray-700 hover:text-black"
								onClick={() => {
									// insert in second to last position
									dispatch(
										setUserWriterSelections({
											docID: docID,
											userWriterSelections: {
												...documentState.userWriterSelections,
												subTopics: documentState.userWriterSelections.subTopics
													.slice(0, index + 1)
													.concat([''])
													.concat(documentState.userWriterSelections.subTopics.slice(index + 1)),
											},
										})
									)
								}}
							>
								<FaPlus />
								<div className="text-xl">Add Subtopic</div>
							</div>
						)}
					</>
				)
			})}
		</>
	)
}

export const SubTopics = ({ generateEssay }: { generateEssay: () => void }) => {
	const redesignedSetupV2FF = useFeatureFlagVariantKey(FEATURE_FLAGS.REDESIGNED_SETUP_V2)
	const dispatch = useDispatch()
	const docID = useDocId()
	const processedSubtopics = useProcessedSubtopics()

	const isDisabled = useMemo(() => {
		return processedSubtopics.some((subtopic) => subtopic.trim() === '')
	}, [processedSubtopics])

	return (
		<div className="subtopics relative">
			<Button
				type="tertiary"
				className="absolute left-0 top-0 sm:block hidden"
				onClick={() =>
					dispatch(
						setIsOnSubtopicsStep({
							docID: docID,
							isOnSubtopicsStep: false,
						})
					)
				}
			>
				<div className="flex items-center gap-2">
					<div className="inline-block text-md w-2 relative right-2">
						<ChevronLeft />
					</div>
					<div>Back</div>
				</div>
			</Button>
			<h6 className="header-sm">Enter subtopics:</h6>
			<p>We've written these to help get you started.</p>
			<SubtopicsInput docID={docID} />
			<Button
				type="tertiary"
				onClick={generateEssay}
				className={`w-full h-12 text-2xl mt-6 ${
					redesignedSetupV2FF === 'test' ? 'rounded capitalize font-medium border-solid' : 'fancy-font'
				}`}
				emphasize
				disabled={isDisabled}
			>
				Generate Essay
			</Button>
		</div>
	)
}
