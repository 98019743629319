import ReactModal from 'react-modal'
import './_Modal.scss'

function Modal({
	open,
	closeModal,
	children,
	portalClassName,
	omitClose = false,
}: {
	open: boolean
	closeModal: () => void
	children: React.ReactNode
	portalClassName?: string
	omitClose?: boolean
}) {
	return (
		<ReactModal
			isOpen={open}
			ariaHideApp={false}
			onRequestClose={() => {
				closeModal()
			}}
			portalClassName={portalClassName}
		>
			{!omitClose && (
				<div className="close" onClick={closeModal}>
					&times;
				</div>
			)}
			{children}
		</ReactModal>
	)
}

export default Modal
