import { useDispatch, useSelector } from 'react-redux'
import { DocumentState } from '../../redux/types'
import { selectDocumentState, setReferences } from '../../redux/docSlice'
import { formatReference, formatReferenceAsString } from '../../helpers/strings'
import ReferencesDropdown from '../WriterOptions/ReferencesDropdown'
import { FaRegTrashAlt } from 'react-icons/fa'
import { useAuth } from '../../contexts/Auth'
import CopyButton from '../Copy'
import { useMemo } from 'react'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'

const References = ({ docId }: { docId: string }) => {
	const referencesCopyFF = useFeatureFlagVariantKey(FEATURE_FLAGS.COPY_REFERENCES)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))
	const dispatch = useDispatch()
	const { currentUser } = useAuth() as any

	const removeReference = async (index: number) => {
		let newReferences = [...documentState.references]
		newReferences.splice(index, 1)
		dispatch(
			setReferences({
				docID: docId,
				references: newReferences,
			})
		)
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				id: docId,
				references: newReferences,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/document/update/', requestOptions)
	}

	const referencesCopyContent = useMemo(() => {
		return documentState?.references
			?.map((ref) => {
				return formatReferenceAsString(documentState?.userWriterSelections.refStyle!, ref)
			})
			.join('\n')
	}, [documentState?.references, documentState?.userWriterSelections?.refStyle])

	if (
		!documentState ||
		documentState.isGenerating ||
		!documentState.setup ||
		!documentState.userWriterSelections.refStyle ||
		documentState.references.length === 0
	) {
		return null
	}

	return (
		<div className="flex flex-col gap-3 mt-8">
			<div className="flex justify-between items-center">
				<div className="flex gap-4 items-center">
					<div className="text-2xl font-semibold">References</div>
					{referencesCopyFF === 'test' && <CopyButton disabled={false} textToCopy={referencesCopyContent} />}
				</div>
				<ReferencesDropdown docID={docId} includeNull={false} />
			</div>
			<div className="flex flex-col gap-6">
				{documentState.references.map((ref, index) => {
					return (
						<div className="grid grid-cols-[1fr,0] items-center overflow-hidden  sm:overflow-visible">
							<div key={index} className="text-lg leading-loose primary-font break-all">
								{formatReference(documentState.userWriterSelections.refStyle!, ref, index)}
							</div>
							<div
								className="cursor-pointer relative md:left-8 group sm:block w-10"
								onClick={() => removeReference(index)}
							>
								<FaRegTrashAlt className="cursor-pointer text-gray-500 group-hover:text-primary" />
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default References
