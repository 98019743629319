import React, { useState, useRef, useEffect } from 'react'
import { NodeViewWrapper } from '@tiptap/react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'
import { useDispatch, useSelector } from 'react-redux'
import {
	selectCategoryScoresLoading,
	selectGraderCategory,
	selectGraderModeOn,
	selectNumAcceptedSuggestions,
	setNumAcceptedSuggestions,
} from '../../redux/systemSlice'
import Diff from '../Diff/Diff'
import Button from '../Buttons'

const EditableSpanComponent = ({ node, editor, getPos }: any) => {
	const graderModeOn = useSelector(selectGraderModeOn)
	const categoryScoresLoading = useSelector(selectCategoryScoresLoading)
	const selectedCategory = useSelector(selectGraderCategory)
	const numAcceptedSuggestions = useSelector(selectNumAcceptedSuggestions)
	const [showMenu, setShowMenu] = useState(false)
	const dispatch = useDispatch()

	const [referenceElement, setReferenceElement] = useState(null)
	const [popperElement, setPopperElement] = useState(null)

	const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
		placement: 'bottom-end',
		modifiers: [
			{
				name: 'preventOverflow',
				options: {
					// @ts-ignore
					boundary: 'viewport',
				},
			},
		],
	})

	const focus = node.attrs['data-focus']
	const editedContent = node.attrs['data-edited']
	const originalContent = node.textContent

	// @ts-ignore
	const highlightColor = {
		grammar: 'rgba(0, 212, 255, .5)',
		content: 'rgba(217, 255, 0, .5)',
		tone: 'rgba(241, 0, 255, .3)',
	}[focus]

	// @ts-ignore
	const highlightHoverColor = {
		grammar: 'rgba(0, 212, 255, .8)',
		content: 'rgba(217, 255, 0, .8)',
		tone: 'rgba(241, 0, 255, .5)',
	}[focus]

	// @ts-ignore
	const underlineColor = {
		grammar: 'rgba(0, 212, 255, 1)',
		content: '#ffe100',
		tone: 'rgba(241, 0, 255, 1)',
	}[focus]

	const applyEdit = (accepted: boolean) => {
		const { state, view } = editor
		const from = getPos()
		const to = from + node.nodeSize

		let transaction

		if (editedContent && editedContent !== '') {
			// Replace the node with the edited content
			transaction = state.tr.replaceWith(from, to, state.schema.text(editedContent))
		} else {
			// Delete the node if editedContent is empty or whitespace
			transaction = state.tr.delete(from, to)
		}

		view.dispatch(transaction)
		editor.view.focus()
		if (accepted) {
			dispatch(setNumAcceptedSuggestions(numAcceptedSuggestions + 1))
		}
	}

	// Call update when showMenu changes to true
	useEffect(() => {
		if (showMenu && update) {
			update()
		}
	}, [showMenu, update])

	// Refs to keep track of hide timeouts
	const hideMenuTimeoutRef = useRef(null)

	if (!graderModeOn || categoryScoresLoading || selectedCategory !== focus) {
		return (
			<NodeViewWrapper as="span" className="editable-span">
				{originalContent}
			</NodeViewWrapper>
		)
	}

	const handleMouseEnter = () => {
		if (hideMenuTimeoutRef.current) {
			clearTimeout(hideMenuTimeoutRef.current)
			hideMenuTimeoutRef.current = null
		}
		editor.view.focus()
		setShowMenu(true)
	}

	const handleMouseLeave = () => {
		// @ts-ignore
		hideMenuTimeoutRef.current = setTimeout(() => {
			setShowMenu(false)
		}, 100) // Delay to allow moving to menu
	}

	return (
		<NodeViewWrapper as="span" className="editable-span">
			<span
				// @ts-ignore
				ref={setReferenceElement}
				style={{
					backgroundColor: showMenu ? highlightHoverColor : highlightColor,
					borderBottom: `2px solid ${underlineColor}`,
				}}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				{originalContent}
			</span>
			{showMenu &&
				ReactDOM.createPortal(
					<div
						// @ts-ignore
						ref={setPopperElement}
						style={styles.popper}
						{...attributes.popper}
						className="text-primary rounded shadow z-50 py-2 px-3 border bg-white md:w-72 w-64 overflow-hidden"
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						<div className="flex flex-col gap-2 items-end">
							<div className="w-full">
								<Diff oldStr={originalContent} newStr={editedContent} />
							</div>
							<div className="border-t border-gray-300 w-full" />
							<div className="flex justify-end gap-4">
								<Button onClick={() => applyEdit(false)} type="primary" className="text-gray-600 hover:text-black">
									Reject
								</Button>
								<Button onClick={() => applyEdit(true)} type="tertiary">
									Apply Edit
								</Button>
							</div>
						</div>
					</div>,
					document.body
				)}
		</NodeViewWrapper>
	)
}

export default EditableSpanComponent
