import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { selectUserPagesCache, setUserPagesCache, setUserPagesLoading } from '../../redux/systemSlice'
import Button from '../Buttons'
import { useCookies } from 'react-cookie'
import { ExistingDoc } from '../UserDocs/UserDocs'
import { useAuth } from '../../contexts/Auth'
import './_Delete.scss'
import { CONSTANTS } from '../../constants'
import Modal from './Modal'

function Delete({
	isOpen,
	closeModal,
	id,
	title,
}: {
	isOpen: boolean
	closeModal: () => void
	id: number
	title: string
}) {
	const [cookies, setCookie] = useCookies(['docs'])
	const dispatch = useDispatch()
	const { currentUser } = useAuth() as any
	const userPagesCache = useSelector(selectUserPagesCache)

	async function deleteDoc(id: number) {
		dispatch(setUserPagesLoading({ value: true }))
		const existingDocs = cookies.docs
		dispatch(
			setUserPagesCache({
				...userPagesCache,
				pages: userPagesCache.pages.filter((el: ExistingDoc) => {
					return el.id !== id
				}),
			})
		)
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				id: id,
				deleted: true,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/document/update/', requestOptions)
			.then((res) => res.json())
			.then(() => {
				setCookie(
					'docs',
					existingDocs
						? existingDocs.filter((el: number) => {
								return el !== id
						  })
						: []
				)
				closeModal()
				dispatch(setUserPagesLoading({ value: false }))
			})
			.catch((err) => {
				Sentry.captureException(err)
				dispatch(setUserPagesLoading({ value: false }))
			})
	}

	return (
		<Modal open={isOpen} closeModal={closeModal} portalClassName="delete">
			<div className="flex flex-col gap-5 items-center">
				<h5 className="text-2xl mt-4">Are you sure you want to delete?</h5>
				<h5 className="fancy-font text-gray-700 text-2xl">
					{title.length
						? title.length > CONSTANTS.MAX_DOCUMENT_DISPLAY_LENGTH
							? title.slice(0, CONSTANTS.MAX_DOCUMENT_DISPLAY_LENGTH) + '...'
							: title
						: 'Untitled'}
				</h5>
				<div className="grid grid-cols-[1fr,1fr] w-full">
					<Button
						type="secondary"
						onClick={() => {
							closeModal()
						}}
						className="text-xl"
					>
						<span>No</span>
					</Button>
					<Button
						type="secondary"
						onClick={() => {
							deleteDoc(id)
						}}
						className="text-xl"
					>
						<span>Yes</span>
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default Delete
