import { configureStore } from '@reduxjs/toolkit'
import systemSlice from './systemSlice'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory as createHistory } from 'history'
import docSlice from './docSlice'

const history = createHistory()
let store = configureStore({
	reducer: {
		system: systemSlice,
		doc: docSlice,
		// router: connectRouter(history),
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})

export default store
