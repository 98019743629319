import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../_Modal.scss'
import './_Login.scss'
import * as Sentry from '@sentry/react'
import { selectUser, setUser } from '../../../redux/systemSlice'
import useCheckout from '../../../hooks/checkout'
import { useAuth } from '../../../contexts/Auth'
import Button from '../../Buttons'
import Modal from '../Modal'
import { NULL_USER } from '../../../constants'
import SignupContent from './Signup'
import LoginContent from './Login'
import { atom, useAtom } from 'jotai'
import useIsPremium from '../../../hooks/useIsPremium'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../../hooks/featureFlags'

type ModalAtomSchema = {
	goToCheckout: boolean
}

const defaultValues: ModalAtomSchema = {
	goToCheckout: false,
}

export const modalAtom = atom<ModalAtomSchema>(defaultValues)

function LoginModal({
	open,
	setModalOpen,
	premiumPath,
	loginVariant = 'login',
	annualPlan,
}: {
	open: boolean
	setModalOpen: (open: boolean) => void
	premiumPath?: boolean
	loginVariant?: 'login' | 'signup'
	annualPlan: boolean
}) {
	const paddleFF = useFeatureFlagVariantKey(FEATURE_FLAGS.PADDLE)
	const user = useSelector(selectUser)
	const [variant, setVariant] = useState<'login' | 'signup'>(premiumPath ? 'signup' : loginVariant)
	const { currentUser } = useAuth() as any
	const [forgotPassword, setForgotPassword] = useState(false)
	const [loginModalState, setLoginModalState] = useAtom(modalAtom)
	const dispatch = useDispatch()
	const { checkout } = useCheckout()
	const isPremium = useIsPremium()

	const goToCheckout = loginModalState.goToCheckout
	const setGoToCheckout = (goToCheckout: boolean) => setLoginModalState({ goToCheckout })

	useEffect(() => {
		if (
			currentUser &&
			goToCheckout &&
			user &&
			user.uid &&
			!isPremium &&
			['control', 'test'].includes(paddleFF as string)
		) {
			checkout(annualPlan)
		}
	}, [currentUser, goToCheckout, user, annualPlan, paddleFF])

	useEffect(() => {
		if (premiumPath) {
			setVariant('signup')
		}
	}, [premiumPath])

	let modalContent = <></>

	if (variant === 'login') {
		modalContent = (
			<LoginContent
				setModalOpen={setModalOpen}
				premiumPath={!!premiumPath}
				setGoToCheckout={setGoToCheckout}
				setVariant={setVariant}
			/>
		)
	} else if (forgotPassword) {
		modalContent = (
			<>
				<div>
					<h5 className="fancy-font header-md">Forgot Password</h5>
					<p>Check your email for further instructions</p>
				</div>
				<Button
					type="tertiary"
					onClick={() => {
						setForgotPassword(false)
					}}
				>
					<span>Login</span>
				</Button>
			</>
		)
	} else {
		modalContent = (
			<SignupContent
				setVariant={setVariant}
				setModalOpen={setModalOpen}
				premiumPath={premiumPath}
				setGoToCheckout={setGoToCheckout}
			/>
		)
	}

	return (
		<>
			<Modal
				portalClassName="login"
				open={open}
				closeModal={() => {
					setModalOpen(false)
					dispatch(setUser(NULL_USER))
					Sentry.setUser(null)
				}}
			>
				{modalContent}
			</Modal>
		</>
	)
}

export default LoginModal
