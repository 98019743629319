import { useCookies } from 'react-cookie'

export const useCookieUser = () => {
	const [cookies, setCookies, removeCookies] = useCookies(['userID'])
	return {
		userIDCookie: cookies.userID,
		setUserIDCookie: (userID: string) => setCookies('userID', userID),
		clearUserIDCookie: () => removeCookies('userID'),
	}
}
