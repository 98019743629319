import { useEffect, useMemo, useState } from 'react'
import AIDetectionScore from './detection-score'
import ScoreIndicator from './score-indicator'
import { BiCheck } from 'react-icons/bi'
import { TbAlertTriangle } from 'react-icons/tb'
import { useWordCount } from './hooks'
import { useAtom } from 'jotai'
import { DetectionResponse, editorAtom } from './constants'
import Button from '../../Components/Buttons'
import { useSelector } from 'react-redux'
import { selectUser } from '../../redux/systemSlice'
import CopyButton from '../../Components/Copy'

const EditorTopSection = ({ detector = false }: { detector?: boolean }) => {
	const [editorState, setEditorState] = useAtom(editorAtom)
	const wordCount = useWordCount(editorState.textInputValue)
	const [copied, setCopied] = useState(false)
	const user = useSelector(selectUser)

	const checkForAI = async () => {
		setEditorState((editorState) => ({ ...editorState, aiDetectionScoreLoading: true }))

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				userId: user.id,
				text: editorState.textInputValue,
				individualScores: true,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/detect/', requestOptions)
			.then((res) => res.json())
			.then((response: DetectionResponse) => {
				setEditorState((editorState) => ({ ...editorState, aiDetectionScoreLoading: false }))
				setEditorState({
					...editorState,
					aiDetectionScore: response,
					aiDetectionScoreText: editorState.textInputValue,
					showDetectionBox: true,
				})
			})
	}

	const disableCheckForAI = useMemo(() => {
		return (
			wordCount === 0 ||
			editorState.isLoadingHumanizeText ||
			editorState.aiDetectionScoreLoading ||
			editorState.isLoadingHumanizeText ||
			editorState.aiDetectionScoreText === editorState.textInputValue
		)
	}, [
		wordCount,
		editorState.isLoadingHumanizeText,
		editorState.aiDetectionScoreText,
		editorState.textInputValue,
		editorState.aiDetectionScoreLoading,
	])

	useEffect(() => {
		if (copied) {
			const timeoutId = setTimeout(() => {
				setCopied(false)
			}, 2000)
			return () => clearTimeout(timeoutId)
		}
	}, [copied])

	return (
		<>
			<div className="flex justify-end w-full ">
				<div
					className={`w-full flex sm:flex-row flex-col sm:gap-0 gap-2 justify-between rounded items-center bg-gray-900 border border-gray-400 p-3 ${
						detector ? 'rounded-tl-none rounded-tr-none' : 'rounded-bl-none rounded-br-none'
					}`}
				>
					<div className="flex gap-3 items-center">
						<div className={`text-sm ${wordCount > 3000 ? 'text-danger-light' : 'text-gray-100'}`}>
							{wordCount} / 3000 word{wordCount === 1 ? '' : 's'}
						</div>
						<CopyButton textToCopy={editorState.textInputValue} disabled={wordCount === 0} iconColor="text-gray-100" />
					</div>
					{editorState.aiDetectionScore &&
						(editorState.aiDetectionScore.human.human > 0.5 ? (
							<div className="flex gap-1 mr-8 grow justify-end">
								<BiCheck className="text-success-light text-lg" />
								<div className="text-sm text-success-light">
									{Math.floor(editorState.aiDetectionScore.human.human * 100)}% Human
								</div>
							</div>
						) : (
							<div className="flex gap-1 mr-8 grow justify-end">
								<TbAlertTriangle className="text-danger-light text-lg" />
								<div className="text-sm text-danger-light">
									{Math.floor(editorState.aiDetectionScore.human.ai * 100)}% Written by AI
								</div>
							</div>
						))}
					<div className="flex gap-5 items-center sm:w-auto w-full flex-wrap sm:flex-nowrap justify-center sm:justify-normal">
						<div className="flex gap-1 items-center">
							<ScoreIndicator score={100} borderColor="border-white" isExampleIndicator />
							<div className="text-sm text-white">AI Detected</div>
						</div>
						<div className="flex gap-1 items-center">
							<ScoreIndicator score={50} borderColor="border-white" isExampleIndicator />
							<div className="text-sm text-white">Mixed</div>
						</div>
						<div className="flex gap-1 items-center">
							<ScoreIndicator score={0} borderColor="border-white" isExampleIndicator />
							<div className="text-sm text-white">Human</div>
						</div>
						<Button
							type="tertiary"
							onClick={checkForAI}
							disabled={disableCheckForAI}
							className="sm:w-auto w-full bg-white"
						>
							{editorState.aiDetectionScoreLoading ? 'Checking for AI...' : 'Check for AI'}
						</Button>
					</div>
				</div>
			</div>
			{!detector && <AIDetectionScore />}
			{!detector && <div className={`oscillating-bar ${editorState.aiDetectionScoreLoading ? 'block' : 'hidden'}`} />}
		</>
	)
}

export default EditorTopSection
