import { useState, useEffect } from 'react'

function generateUniqueId() {
	// Creates a unique ID using a combination of random numbers and current timestamp
	return `id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`
}

function useUniqueId() {
	const [uniqueId, setUniqueId] = useState<string | undefined>(undefined)

	useEffect(() => {
		// Try to get the unique ID from local storage
		let id = localStorage.getItem('uniqueId')

		if (!id) {
			// If no ID is found, generate a new one and save it to local storage
			id = generateUniqueId()
			localStorage.setItem('uniqueId', id)
		}

		// Set the ID in state
		setUniqueId(id)
	}, [])

	return uniqueId
}

export default useUniqueId
