import { useCookies } from 'react-cookie'
import useIsPremium from '../hooks/useIsPremium'

const ToolCredits = () => {
	const [cookies] = useCookies(['humanizerCredits'])
	const isPremium = useIsPremium()
	const pathname = window.location.pathname

	if (isPremium || pathname.includes('detector')) {
		return null
	}

	return (
		<div className="flex gap-1">
			<div className="text-gray-600">Free credits: </div>
			<div className="font-semibold">{Math.max(0, 300 - (cookies.humanizerCredits ?? 0))} words</div>
		</div>
	)
}

export default ToolCredits
