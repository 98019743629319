import './_404.scss'

function Unknown() {
	const pageName = 'unknown'

	return (
		<main id={pageName}>
			<div className="rollingContainer"></div>
			<h2 className="highlighted header-2xl">
				<span>404</span>
			</h2>
		</main>
	)
}

export default Unknown
