import { Extension } from '@tiptap/core'
import { Plugin } from 'prosemirror-state'

const CopyEventHandler = Extension.create({
	name: 'copyEventHandler',

	addProseMirrorPlugins() {
		return [
			new Plugin({
				props: {
					handleDOMEvents: {
						copy: (view, event) => {
							if (this.options.onCopy) {
								this.options.onCopy(event)
							}
							return false // Allow default copy behavior
						},
					},
				},
			}),
		]
	},
})

export default CopyEventHandler
