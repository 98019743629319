import './_Loader.scss'

function Loader({ small }: { small?: boolean }) {
	return (
		<div className="spinner">
			<span></span>
		</div>
	)
}

export default Loader
