import { useEffect, useMemo } from 'react'
import './_WriterOptions.scss'
import { useDispatch, useSelector } from 'react-redux'
import { CONSTANTS, TYPE_OPTIONS, HUMANIZER_OPTIONS, DOC_TEMPLATES } from '../../constants'
import { selectDocumentState, setUserWriterSelections } from '../../redux/docSlice'
import Button from '../Buttons'
import { isEqual } from 'lodash'
import { DocumentState, UserWriterSelections } from '../../redux/types'
import { blankUserWriterSelections } from '../../redux/constants'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS, useFreeWordLimit } from '../../hooks/featureFlags'
import WritingStyleSelection from './WritingStyle'
import ReferencesSelection from './References'
import { FaLock, FaX } from 'react-icons/fa6'
import { Tooltip } from '@mui/material'
import { IoInformationCircleOutline } from 'react-icons/io5'
import useIsPremium from '../../hooks/useIsPremium'
import ThesisStatement from './ThesisStatement'

type Props = {
	type: string
	premiumUser: boolean
	formValid: boolean
	localUserWriterSelections: UserWriterSelections
	docID: string
	setSubscriptionModalOpen: (open: boolean) => void
	topicValue: string
}

function WriterOptions(props: Props) {
	const freeWordLimit = useFreeWordLimit()
	const dispatch = useDispatch()
	const stealthGptFF = useFeatureFlagVariantKey(FEATURE_FLAGS.STEALTH_GPT)
	const redesignedSetupV2FF = useFeatureFlagVariantKey(FEATURE_FLAGS.REDESIGNED_SETUP_V2)
	const thesisGenerationFF = useFeatureFlagVariantKey(FEATURE_FLAGS.THESIS_GENERATION)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, props.docID))
	const isPremium = useIsPremium()
	const showStealthGpt = stealthGptFF === 'stealth-checkbox' && isPremium

	//Set redux if we have local storage for this document on component mount
	useEffect(() => {
		if (!isEqual(props.localUserWriterSelections, blankUserWriterSelections)) {
			dispatch(
				setUserWriterSelections({
					docID: props.docID,
					userWriterSelections: props.localUserWriterSelections,
				})
			)
		}
		//Update only on initial page load
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const pageCount = useMemo(() => {
		const wordCount = documentState.userWriterSelections?.wordCount ?? freeWordLimit
		return (wordCount / 500).toFixed(1)
	}, [documentState.userWriterSelections?.wordCount])

	const checkbox = (
		<div
			className={`flex justify-between gap-2 mt-12 items-center cursor-pointer ${isPremium ? 'group' : 'opacity-50'}`}
			onClick={(e) => {
				if (!isPremium) {
					props.setSubscriptionModalOpen(true)
					return
				}
				dispatch(
					setUserWriterSelections({
						docID: props.docID,
						userWriterSelections: {
							humanizerIntensity: documentState.userWriterSelections?.humanizerIntensity === 'NONE' ? 'HIGH' : 'NONE',
						},
					})
				)
			}}
		>
			<div className="flex gap-3 items-center grow">
				<Tooltip title={isPremium ? '' : 'Upgrade to our premium plan to enable this feature.'} placement="top-start">
					<div className="md:text-2xl text-xl font-semibold">Humanize Text</div>
				</Tooltip>
				{!isPremium && (
					<Tooltip
						title="Transform AI-generated text to undetectable, human writing. Our humanizer is optimized to pass Turnitin's detection."
						placement="top-start"
					>
						<div>
							<IoInformationCircleOutline className="text-2xl" />
						</div>
					</Tooltip>
				)}
			</div>
			<div className="flex justify-center items-center p-2 border border-primary group-hover:bg-secondary">
				{isPremium ? (
					<FaX
						className={documentState.userWriterSelections?.humanizerIntensity === 'NONE' ? 'invisible' : 'visible'}
					/>
				) : (
					<Tooltip title="Upgrade to our premium plan to enable this feature." placement="top-end">
						<div>
							<FaLock />
						</div>
					</Tooltip>
				)}
			</div>
		</div>
	)

	return (
		<>
			<div id="writerOptions">
				{props.type !== DOC_TEMPLATES.Outline && (
					<>
						<h4
							className={`sliderValue mb-3 ${
								redesignedSetupV2FF === 'test' ? 'text-4xl mt-10' : 'md:text-5xl text-4xl mt-12'
							}`}
						>
							<input
								onChange={(e) => {
									dispatch(
										setUserWriterSelections({
											docID: props.docID,
											userWriterSelections: { wordCount: parseInt(e.target.value) },
										})
									)
								}}
								onBlur={() => {
									const input = documentState.userWriterSelections?.wordCount ?? freeWordLimit
									if (input > CONSTANTS.WORD_COUNT_MAX) {
										dispatch(
											setUserWriterSelections({
												docID: props.docID,
												userWriterSelections: { wordCount: CONSTANTS.WORD_COUNT_MAX },
											})
										)
									} else if (input < CONSTANTS.WORD_COUNT_MIN) {
										dispatch(
											setUserWriterSelections({
												docID: props.docID,
												userWriterSelections: { wordCount: CONSTANTS.WORD_COUNT_MIN },
											})
										)
									}
								}}
								type="number"
								className={`value ${redesignedSetupV2FF === 'test' ? '!w-[100px] text-center' : '!w-[130px]'}`}
								value={documentState.userWriterSelections?.wordCount ?? freeWordLimit}
								placeholder=""
							/>
							<span
								className={
									redesignedSetupV2FF === 'test' ? 'text-2xl relative bottom-0.5 text-gray-700' : 'units fancy-font'
								}
							>
								words
								<span className="md:inline hidden">
									, or <span className={redesignedSetupV2FF === 'test' ? 'relative top-[5px] ml-0.5' : ''}>~</span>
									{pageCount} pages
								</span>
							</span>
						</h4>
						<input
							type="range"
							min={CONSTANTS.WORD_COUNT_MIN}
							max={CONSTANTS.WORD_COUNT_MAX}
							step={50}
							className="slider"
							value={documentState.userWriterSelections?.wordCount ?? freeWordLimit}
							onChange={(e) => {
								dispatch(
									setUserWriterSelections({
										docID: props.docID,
										userWriterSelections: { wordCount: parseInt(e.target.value) },
									})
								)
							}}
						/>
						{isPremium && !showStealthGpt && (
							<>
								<div className="humanizerContainer">
									<div className={`text-xl mb-3 font-semibold ${redesignedSetupV2FF === 'test' ? 'text-left' : ''}`}>
										Humanizer intensity
									</div>
									<div className="grid md:grid-cols-4 grid-cols-2 gap-3">
										<>
											{HUMANIZER_OPTIONS.map((str, index) => {
												return redesignedSetupV2FF === 'test' ? (
													<Button
														className={`w-full text-lg rounded py-1 font-medium ${
															str === documentState.userWriterSelections?.humanizerIntensity
																? ''
																: 'hover:bg-gray-100 border-dotted border border-gray-500'
														}`}
														key={str + index}
														type="primary"
														emphasize={str === documentState.userWriterSelections?.humanizerIntensity}
														onClick={(e) => {
															dispatch(
																setUserWriterSelections({
																	docID: props.docID,
																	userWriterSelections: { humanizerIntensity: str },
																})
															)
														}}
													>
														<span className="capitalize">{str.toLowerCase()}</span>
													</Button>
												) : (
													<Button
														className={`w-full text-xl ${
															str === documentState.userWriterSelections?.humanizerIntensity && 'bg-secondary'
														}`}
														key={str + index}
														type="secondary"
														onClick={(e) => {
															dispatch(
																setUserWriterSelections({
																	docID: props.docID,
																	userWriterSelections: { humanizerIntensity: str },
																})
															)
														}}
													>
														<span>{str}</span>
													</Button>
												)
											})}
										</>
									</div>
								</div>
							</>
						)}
						{showStealthGpt && checkbox}
					</>
				)}
				<div className="toneTypeContainer">
					<div className={`text-xl mb-3 font-semibold ${redesignedSetupV2FF === 'test' ? 'text-left' : 'text-center'}`}>
						{redesignedSetupV2FF === 'test' ? 'Essay type' : 'Select an essay type'}
					</div>
					<div
						className={`grid md:grid-cols-5 grid-cols-1 gap-3 ${redesignedSetupV2FF === 'test' ? '' : 'toneTypeList'}`}
					>
						{TYPE_OPTIONS.map((str, index) => {
							return redesignedSetupV2FF === 'test' ? (
								<Button
									className={`w-full text-lg rounded py-1 font-medium ${
										str === documentState.userWriterSelections?.type
											? ''
											: 'hover:bg-gray-100 border-dotted border border-gray-500'
									}`}
									key={str + index}
									type="primary"
									emphasize={str === documentState.userWriterSelections?.type}
									onClick={(e) => {
										dispatch(
											setUserWriterSelections({
												docID: props.docID,
												userWriterSelections: { type: str },
											})
										)
									}}
								>
									<span>
										{str} {index === 0 && '(default)'}
									</span>
								</Button>
							) : (
								<Button
									className={`toneTypeButton ${str === documentState.userWriterSelections?.type && 'selected'}`}
									key={str + index}
									type="primary"
									onClick={(e) => {
										dispatch(
											setUserWriterSelections({
												docID: props.docID,
												userWriterSelections: { type: str },
											})
										)
									}}
								>
									<span>{str}</span>
								</Button>
							)
						})}
					</div>
				</div>
				{props.type !== DOC_TEMPLATES.Outline && (
					<>
						<WritingStyleSelection docID={props.docID} />
						<ReferencesSelection docID={props.docID} />
					</>
				)}
				{thesisGenerationFF === 'test' && <ThesisStatement topicValue={props.topicValue} />}
			</div>
		</>
	)
}

export default WriterOptions
