import { Popper } from '@mui/material'
import { Editor, isNodeSelection, posToDOMRect } from '@tiptap/core'
import './_ControlledBubbleMenu.scss'
import { useCallback, useEffect, useRef } from 'react'

type Props = {
	editor: Editor
	open: boolean
	children: React.ReactNode
	onClickAway: () => void
}

const ControlledBubbleMenu: React.FC<Props> = ({ editor, open, children, onClickAway }: Props) => {
	const compRef = useRef<any>()

  const handleClickOutside = useCallback((event: any) => {
    if (compRef.current && !compRef.current.contains(event.target)) {
      onClickAway()
    }
  }, [compRef, onClickAway])

	//When user clicks outside of the bubblemenu
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [handleClickOutside])

	return (
		<Popper
			ref={compRef}
			open={open}
			placement="auto"
			className="controlledBubbleMenu"
			modifiers={[
				{
					name: 'offset',
					options: {
						// Add a slight vertical offset for the popper from the current selection
						offset: [0, 4],
					},
				},
				{
					name: 'flip',
					enabled: true,
					options: {
						// We'll reposition (to one of the below fallback placements) whenever our Popper goes
						// outside of the editor. (This is necessary since our children aren't actually rendered
						// here, but instead with a portal, so the editor DOM node isn't a parent.)
						boundary: editor.options.element,
						fallbackPlacements: ['auto-start', 'auto-end', 'bottom-start', 'bottom-end', 'top-end'],
						padding: 0,
					},
				},
			]}
			anchorEl={() => {
				// The logic here is taken from the positioning implementation in Tiptap's BubbleMenuPlugin
				// https://github.com/ueberdosis/tiptap/blob/16bec4e9d0c99feded855b261edb6e0d3f0bad21/packages/extension-bubble-menu/src/bubble-menu-plugin.ts#L183-L193
				const { ranges } = editor.state.selection
				const from = Math.min(...ranges.map((range) => range.$from.pos))
				const to = Math.max(...ranges.map((range) => range.$to.pos))

				return {
					getBoundingClientRect: () => {
						if (isNodeSelection(editor.state.selection)) {
							const node = editor.view.nodeDOM(from) as HTMLElement

							if (node) {
								return node.getBoundingClientRect()
							}
						}

						return posToDOMRect(editor.view, from, to)
					},
				}
			}}
		>
			{children}
		</Popper>
	)
}

export default ControlledBubbleMenu
