import { useAtom } from 'jotai'
import * as Sentry from '@sentry/react'
import { useMemo } from 'react'
import { editorAtom } from './constants'
import { useCookies } from 'react-cookie'

export const useWordCount = (text: string) => {
	return useMemo(() => {
		const trimmedValue = text.trim().replace(/\s+/g, ' ')
		return trimmedValue.length === 0 ? 0 : trimmedValue.split(' ').length
	}, [text])
}

export const useHumanizeText = () => {
	const [editorState, setEditorState] = useAtom(editorAtom)
	const [cookies, setCookies] = useCookies(['humanizerCredits'])
	const wordCount = useWordCount(editorState.textInputValue)

	async function makeHumanizerRequest(textToHumanize: string) {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				text: textToHumanize,
				humanizerIntensity: editorState.humanizerIntensityLevel,
				purpose: editorState.purpose,
				literacyLevel: editorState.literacyLevel,
			}),
		}
		try {
			// Await the fetch call and its conversion to JSON
			const response = await fetch(process.env.REACT_APP_API_ROOT + '/essay/humanize/', requestOptions)
			const result = await response.json()
			return result
		} catch (e) {
			Sentry.captureException(e)
			// Ensure that in case of error, the function returns a fallback or error indicator
			return '' // Consider returning null or a specific error object depending on your handling logic
		}
	}

	const humanize = async (retry: boolean) => {
		setEditorState({
			...editorState,
			isLoadingHumanizeText: true,
		})

		const textToHumanize = editorState.previousHumanization
			? editorState.previousHumanization.text
			: editorState.textInputValue

		const humanizedTextResult = await makeHumanizerRequest(textToHumanize)
		setEditorState((editorState) => ({
			...editorState,
			isLoadingHumanizeText: false,
		}))
		setEditorState((editorState) => ({
			...editorState,
			textInputValue: humanizedTextResult.content,
		}))

		if (!retry) {
			setCookies('humanizerCredits', parseInt(cookies.humanizerCredits ?? 0) + wordCount)
		}

		setTimeout(
			() =>
				setEditorState((editorState) => ({
					...editorState,
					previousHumanization: {
						text: textToHumanize,
						intensityLevel: editorState.humanizerIntensityLevel,
					},
				})),
			500
		)
	}

	return humanize
}
