import { useMemo } from 'react'
import { useDocId } from './docID'
import { DocumentState } from '../redux/types'
import { useSelector } from 'react-redux'
import { selectDocumentState } from '../redux/docSlice'

const useDocWordCount = () => {
	const docID = useDocId()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))

	const wordCount = useMemo(
		() =>
			documentState && documentState.content && documentState.content.length > 0
				? documentState.content
						.replace(/\s+/g, ' ')
						.split(' ')
						.filter((word: string) => {
							return word.length !== 0
						}).length
				: 0,
		[documentState.content]
	)

	return wordCount
}

export default useDocWordCount
