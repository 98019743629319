import './_Create.scss'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../Components/Buttons'
import { CONSTANTS, DOC_TEMPLATES } from '../../constants'
import TextareaAutosize from 'react-textarea-autosize'
import { selectDocumentState, setUserWriterSelections } from '../../redux/docSlice'
import WriterOptions from '../../Components/WriterOptions/WriterOptions'
import { useLocalStorage } from '../../helpers/utility'
import { DocumentState } from '../../redux/types'
import { blankUserWriterSelections } from '../../redux/constants'
import useWriteEssay from '../../hooks/useWriteEssay'
import ErrorModal from '../../Components/Modals/Error'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'
import TitleQuality from './TitleQuality'
import useIsPremium from '../../hooks/useIsPremium'
import SteppedEssaySetup from './Steps'
import { SubTopics } from './Subtopics'

const placeholderText = 'Type your topic here...'

function Setup({
	type,
	docID,
	setSubscriptionModalOpen,
}: {
	type: keyof typeof DOC_TEMPLATES | undefined
	docID: string
	setSubscriptionModalOpen: (open: boolean) => void
}) {
	const defaultWordCountFF = useFeatureFlagVariantKey(FEATURE_FLAGS.WORD_COUNT)
	const redesignedSetupV2FF = useFeatureFlagVariantKey(FEATURE_FLAGS.REDESIGNED_SETUP_V2)
	const steppedEssaySetup = useFeatureFlagVariantKey(FEATURE_FLAGS.STEPPED_ESSAY_SETUP)
	const dispatch = useDispatch()
	const posthog = usePostHog()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const [topicValue, setTopicValue] = useState<string>(documentState?.title ?? '')
	const [topicInputFocused, setTopicInputFocused] = useState<boolean>(false)
	const [submitAttempted, setSubmitAttempted] = useState<boolean>(false)
	const [displayedPlaceholder, setDisplayedPlaceholder] = useState('')
	const formValid = topicValue !== undefined && topicValue.length !== 0
	const [localUserWriterSelections, setLocalUserWriterSelections] = useLocalStorage(docID, blankUserWriterSelections)
	const defaultWordCount = defaultWordCountFF === '150' ? 150 : 250
	const isPremium = useIsPremium()

	const [errorModalOpen, setErrorModalOpen] = useState(false)
	const { generateEssay, getSubtopics } = useWriteEssay({
		docID: docID,
		type: type,
		topicValue,
		setTopicValue,
		setLocalUserWriterSelections: (selections) => {
			setLocalUserWriterSelections(selections)
			dispatch(setUserWriterSelections({ docID, userWriterSelections: selections }))
		},
		setErrorModalOpen: setErrorModalOpen,
	})

	useEffect(() => {
		let index = 0
		const interval = setInterval(() => {
			if (index < placeholderText.length) {
				setDisplayedPlaceholder((prev) => prev + placeholderText[index])
				index++
			} else {
				clearInterval(interval)
			}
		}, 30)

		return () => clearInterval(interval) // Cleanup on unmount
	}, [])

	useEffect(() => {
		posthog.capture('setup-page-viewed')
	}, [])

	useEffect(() => {
		if (!documentState.setup) {
			dispatch(
				setUserWriterSelections({
					docID,
					userWriterSelections: { ...documentState.userWriterSelections, wordCount: defaultWordCount },
				})
			)
		}
	}, [documentState.setup, defaultWordCount])

	useEffect(() => {
		setTopicValue(documentState?.title ?? '')
	}, [documentState?.title])

	if (!!documentState.setup || !type) {
		return null
	}

	if (steppedEssaySetup === 'test') {
		return (
			<>
				<ErrorModal open={errorModalOpen} closeModal={() => setErrorModalOpen(false)} />
				<SteppedEssaySetup type={type} setErrorModalOpen={setErrorModalOpen} />
			</>
		)
	}

	return (
		<>
			<ErrorModal open={errorModalOpen} closeModal={() => setErrorModalOpen(false)} />
			<div className="headerContainer">
				{!documentState.isOnSubtopicsStep &&
					(redesignedSetupV2FF === 'test' ? (
						<TextareaAutosize
							placeholder={displayedPlaceholder}
							className={`pb-2 w-full resize-none outline-none ${
								topicValue.length > 100 ? 'md:text-3xl text-2xl' : 'md:text-4xl text-3xl'
							} font-medium`}
							autoFocus
							onFocus={() => {
								setTopicInputFocused(true)
							}}
							onBlur={() => {
								setTopicInputFocused(false)
							}}
							onChange={(e) => {
								setTopicValue(e.target.value)
							}}
							minRows={1}
							id="topicHeader"
							maxRows={topicInputFocused ? undefined : 3}
							maxLength={CONSTANTS.MAX_TOPIC_LENGTH}
							value={topicValue}
						/>
					) : (
						<TextareaAutosize
							placeholder="Type your topic..."
							id="topicHeader"
							className={`pb-2 w-full resize-none outline-none font-semibold ${
								topicValue.length > 100 ? 'md:text-3xl text-2xl' : 'md:text-4xl text-3xl'
							}`}
							autoFocus
							onFocus={() => {
								setTopicInputFocused(true)
							}}
							onBlur={() => {
								setTopicInputFocused(false)
							}}
							onChange={(e) => {
								setTopicValue(e.target.value)
							}}
							minRows={1}
							maxRows={topicInputFocused ? undefined : 3}
							maxLength={CONSTANTS.MAX_TOPIC_LENGTH}
							value={topicValue}
						/>
					))}

				{!documentState.isOnSubtopicsStep && (
					<>
						{documentState.isGeneratingSubtopics ? (
							<div className="topicDivider oscillating-bar"></div>
						) : (
							<TitleQuality topic={topicValue} />
						)}
					</>
				)}
				{submitAttempted && !formValid && <div className="text-danger-default text-sm mt-2">Please enter a topic</div>}
				{!documentState.isOnSubtopicsStep && (
					<WriterOptions
						type={type!}
						premiumUser={isPremium}
						formValid={formValid}
						localUserWriterSelections={localUserWriterSelections}
						docID={docID}
						setSubscriptionModalOpen={setSubscriptionModalOpen}
						topicValue={topicValue}
					/>
				)}
				{!documentState.isOnSubtopicsStep && (
					<Button
						type="tertiary"
						onClick={() => {
							if (!formValid) {
								setSubmitAttempted(true)
								window.scrollTo({ top: 0, behavior: 'smooth' })
								return
							}
							if (documentState.userWriterSelections.wordCount >= CONSTANTS.TOPIC_GENERATION_BREAKPOINT) {
								getSubtopics()
							} else {
								generateEssay()
								posthog.capture('generate-essay', {
									word_count: documentState.userWriterSelections.wordCount,
									humanizer_intensity: documentState.userWriterSelections.humanizerIntensity,
									ref_style: documentState.userWriterSelections.refStyle,
									essay_type: documentState.userWriterSelections.type,
								})
							}
						}}
						className={`w-full h-12 text-2xl mt-12 ${
							redesignedSetupV2FF === 'test' ? 'rounded capitalize font-medium border-solid' : 'fancy-font'
						}`}
						emphasize
					>
						<span>
							{type === DOC_TEMPLATES.Writer
								? documentState.isGeneratingSubtopics
									? 'Loading...'
									: 'Generate Essay'
								: 'Create Outline'}
						</span>
					</Button>
				)}
				{documentState.isOnSubtopicsStep && (
					<SubTopics
						generateEssay={() => {
							posthog.capture('generate-essay', {
								word_count: documentState.userWriterSelections.wordCount,
								humanizer_intensity: documentState.userWriterSelections.humanizerIntensity,
								ref_style: documentState.userWriterSelections.refStyle,
								essay_type: documentState.userWriterSelections.type,
							})
							generateEssay()
						}}
					/>
				)}
			</div>
		</>
	)
}

export default Setup
