import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CONSTANTS } from '../constants'
import { setLastAggregatePageVisited } from '../redux/systemSlice'
import './_Pricing.scss'
import Footer from '../Components/Footer'
import useEnterApp from '../hooks/enterApp'
import Button from '../Components/Buttons'
import { useIsInApp } from '../helpers/utility'
import { useAuth } from '../contexts/Auth'

function Pricing() {
	const pageName = 'pricing'
	const dispatch = useDispatch()
	const history = useHistory()
	const isInApp = useIsInApp()
	const { currentUser } = useAuth() as any
	const { enterApp } = useEnterApp({ history: history })

	useEffect(() => {
		dispatch(setLastAggregatePageVisited({ value: '/pricing' }))
		//Update only on initial page load
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<main id={pageName}>
			<div className="paddedContainer">
				<h3 className="header-lg">Pricing</h3>
				<p>
					The Good AI is an AI essay writer that serves as a powerful tool to combat writer's block by generating essay
					rough drafts and tools to help refine your writing. By analyzing your desired topic and your writing style, it
					offers relevent essay suggestions and provides an idea of how to get started. We offer both a free plan and an
					affordable premium plan to suit your needs.
				</p>
				<div className="flex">
					<div className="basic">
						<h4 className="highlighted header-md">Free</h4>
						<h5 className="header-sm">Basic Plan</h5>
						<p>Up to {CONSTANTS.FREE_WORD_LIMIT} word essays</p>
						<p>No AI auto-complete</p>
						<p>AI essay outliner</p>
						<p>24/7 customer service</p>
						<Button type="secondary" onClick={enterApp}>
							<span>{isInApp ? (currentUser ? 'ACCOUNT' : 'LOG IN') : 'Use for Free'}</span>
						</Button>
					</div>
					<div className="premium">
						<h4 className="highlighted header-md">$5/month</h4>
						<h5 className="header-sm">Premium Plan</h5>
						<p>Up to {CONSTANTS.WORD_COUNT_MAX} word essays</p>
						<p>AI auto-complete</p>
						<p>AI essay outliner</p>
						<p>24/7 customer service</p>
						<Button type="secondary" onClick={enterApp}>
							<span>{isInApp ? (currentUser ? 'ACCOUNT' : 'LOG IN') : 'Use for Free'}</span>
						</Button>
					</div>
				</div>
			</div>
			<Footer />
		</main>
	)
}

export default Pricing
