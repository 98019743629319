import { useEffect, useState } from 'react'
import { useAuth } from '../contexts/Auth'

export const useToken = () => {
	const { currentUser } = useAuth() as any

	const [token, setToken] = useState<any>(undefined)
	useEffect(() => {
		async function getUserToken() {
			if (!currentUser) return
			const token = await currentUser?.getIdToken()
			setToken(token)
		}
		getUserToken()
	}, [currentUser])

	return token
}
