import { useEffect, useState } from 'react'

const useLoadingDots = (active: boolean) => {
	const [dots, setDots] = useState('.')

	useEffect(() => {
		let intervalId: any

		if (active) {
			intervalId = setInterval(() => {
				setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''))
			}, 400)
		} else {
			setDots('')
		}

		return () => clearInterval(intervalId)
	}, [active])

	return dots
}

export default useLoadingDots
