import ReactDOM from 'react-dom'
import App from './App'
import './styles/_index.scss'
import * as Sentry from '@sentry/react'

Sentry.init({
	dsn: 'https://f3e478a446c041fc8304704edd70770a@o4504884714799104.ingest.sentry.io/4504884714799104',
	integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
	environment: process.env.NODE_ENV,

	replaysSessionSampleRate: 0.1,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 0.5,
	tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.3 : 1.0,
	ignoreErrors: [
		//Abort error caused by users cancelling requests, not preventable from our side
		'/^AbortError: AbortError$/',
		'/^The operation was aborted.$/',
		'/^AbortError: The transaction was aborted, so the request cannot be fulfilled.$/',
		//Network request failed errors are handled and not preventable from our side
		// eslint-disable-next-line no-useless-escape
		'/^Firebase: Error \(auth\/network-request-failed\).$/',
	],
})

ReactDOM.render(<App />, document.getElementById('root'))
