import { TextareaAutosize } from '@mui/material'
import { DocumentState } from '../../redux/types'
import { useDispatch, useSelector } from 'react-redux'
import { selectDocumentState, setUserWriterSelections } from '../../redux/docSlice'
import { useDocId } from '../../hooks/docID'
import Button from '../Buttons'
import { FaWandMagicSparkles } from 'react-icons/fa6'
import { useState } from 'react'
import { selectUser } from '../../redux/systemSlice'
import * as Sentry from '@sentry/react'

const ThesisStatement = ({ topicValue }: { topicValue: string }) => {
	const user = useSelector(selectUser)
	const docID = useDocId()
	const dispatch = useDispatch()
	const [thesisGenerating, setThesisGenerating] = useState(false)
	const documentState: DocumentState | undefined = useSelector((state) => selectDocumentState(state, docID))

	const handleGenerateThesis = async () => {
		setThesisGenerating(true)

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				title: topicValue,
				type: documentState?.userWriterSelections.type,
				userId: user.id,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/generate-thesis/', requestOptions)
			.then((res) => res.json())
			.then((response: string) => {
				dispatch(
					setUserWriterSelections({
						docID: docID,
						userWriterSelections: { thesisStatement: response },
					})
				)
				setThesisGenerating(false)
			})
			.catch((err) => {
				Sentry.captureException(err)
			})
	}
	return (
		<div className="flex flex-col gap-2 mt-7">
			<div className="flex justify-between items-center">
				<div className="text-xl font-semibold">
					Thesis Statement <span className="text-gray-600">(optional)</span>:
				</div>
				{topicValue.trim().length > 0 && (
					<Button
						type="tertiary"
						emphasize
						className="rounded normal-case"
						onClick={handleGenerateThesis}
						disabled={thesisGenerating}
					>
						<div className="flex items-center gap-2 py-0.5">
							<FaWandMagicSparkles className="w-5 md:w-auto" />
							{thesisGenerating ? 'Generating...' : 'Generate thesis'}
						</div>
					</Button>
				)}
			</div>
			<TextareaAutosize
				placeholder="Type your thesis statement here..."
				className="w-full resize-none outline-none  border border-gray-500 p-2 rounded focus:border-gray-700"
				onChange={(e) => {
					dispatch(
						setUserWriterSelections({
							docID: docID,
							userWriterSelections: { thesisStatement: e.target.value },
						})
					)
				}}
				minRows={4}
				maxRows={4}
				value={documentState?.userWriterSelections.thesisStatement ?? ''}
			/>
		</div>
	)
}

export default ThesisStatement
