import { FaChevronDown } from 'react-icons/fa6'
import Button from '../Buttons'
import Dropdown from '../Dropdown/Dropdown'
import { useRef, useState } from 'react'

const OptionDropdown = ({
	options,
	selectedOption,
	setSelectedOption,
	displayNames,
}: {
	options: (string | null)[]
	selectedOption: string | null
	setSelectedOption: (option: string) => void
	displayNames?: { [key: string]: string }
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const buttonRef = useRef(null)

	return (
		<div className="relative max-w-52">
			<Button
				type="primary"
				btnRef={buttonRef}
				onClick={() => setDropdownOpen(!dropdownOpen)}
				className="border border-gray-600 px-4 py-1 w-full rounded border-solid hover:bg-gray-100"
			>
				<div className="flex items-center gap-3 text-xl justify-between">
					<div className="capitalize text-[16px]">
						{displayNames ? displayNames[selectedOption ?? 'None'] : selectedOption?.toLocaleLowerCase() ?? 'None'}
					</div>
					<FaChevronDown className="text-sm" />
				</div>
			</Button>
			<Dropdown
				closeButton={buttonRef}
				open={dropdownOpen}
				setOpen={setDropdownOpen}
				body={
					<div className="flex flex-col gap-0.5 max-h-36 overflow-y-auto">
						{options.map((type: any, index: number) => (
							<div
								key={type + index}
								onClick={() => {
									setSelectedOption(type)
									setDropdownOpen(false)
								}}
								className={`px-3 py-1 cursor-pointer hover:bg-gray-100 font-semibold text-left ${
									!type && 'text-gray-700'
								} ${selectedOption === type ? 'bg-gray-200' : ''}`}
							>
								<span className="capitalize">
									{displayNames ? displayNames[type ?? 'None'] : type?.toLocaleLowerCase() ?? 'None'}
								</span>
							</div>
						))}
					</div>
				}
				className="absolute top-10 right-0 w-full bg-white border border-gray-300 shadow-md z-10"
			/>
		</div>
	)
}

export default OptionDropdown
