import Button from '../Buttons'
import './_Detector.scss'
import { selectGraderModeOn, selectUser, setGraderModeOn, setNumAcceptedSuggestions } from '../../redux/systemSlice'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { DocumentState } from '../../redux/types'
import { selectDocumentState } from '../../redux/docSlice'
import { htmlToString } from '../../helpers/utility'
import { useDocId } from '../../hooks/docID'
import { useAuth } from '../../contexts/Auth'
import { useRef, useState } from 'react'
import { CONSTANTS } from '../../constants'
import RubricModal from '../Modals/Rubric'
import { TbReportAnalytics } from 'react-icons/tb'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'
import Toggle from '../Toggle'
import useDocWordCount from '../../hooks/useDocWordCount'

export type GraderRubric = {
	constructiveComments: string
	thesisStatement: string
	weakWords: string
	relevanceToTopic: number
	depthOfAnalysis: number
	evidenceAndSupport: number
	consistencyOfTone: number
	correctGrammar: number
	correctSpelling: number
	goodWordChoice: number
	qualityOfParagraphTransitions: number
}

const Grader = () => {
	const [modalOpen, setModalOpen] = useState(false)
	const [scoreLoading, setScoreLoading] = useState(false)
	const [gradeResult, setGradeResult] = useState<GraderRubric | null>(null)
	const docID = useDocId()
	const { currentUser } = useAuth() as any
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const user = useSelector(selectUser)
	const posthog = usePostHog()
	const graderV2 = useFeatureFlagVariantKey(FEATURE_FLAGS.GRADER_V2)
	const graderModeOn = useSelector(selectGraderModeOn)
	const wordCount = useDocWordCount()
	const dispatch = useDispatch()

	const handleGrade = async () => {
		posthog.capture('grade-essay')
		setScoreLoading(true)
		setModalOpen(true)

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				topic: htmlToString(documentState.title),
				essay: htmlToString(documentState.content),
				userId: user.id,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/grade/', requestOptions)
			.then((res) => res.json())
			.then((response: GraderRubric) => {
				setGradeResult(response)
				setScoreLoading(false)
			})
			.catch((err) => {
				Sentry.captureException(err)
			})
	}

	if (graderV2 === 'test') {
		return (
			<div className="flex gap-2 items-center cursor-pointer" onClick={() => dispatch(setGraderModeOn(!graderModeOn))}>
				<div className="text-sm">Grader Mode</div>
				<Toggle
					isToggled={graderModeOn}
					onToggle={() => {
						dispatch(setNumAcceptedSuggestions(0))
					}}
				/>
			</div>
		)
	}

	return (
		<>
			<RubricModal isOpen={modalOpen} onClose={() => setModalOpen(false)} loading={scoreLoading} rubric={gradeResult} />
			<div>
				<Button
					type="tertiary"
					disabled={
						documentState &&
						(documentState.isHumanizing ||
							documentState.isGenerating ||
							documentState.isTypingAddMore ||
							documentState.isTypingEssay ||
							documentState.content === '' ||
							documentState.content === CONSTANTS.EMPTY_DOCUMENT ||
							wordCount < 20)
					}
					onClick={handleGrade}
					className="px-2 md:px-5"
				>
					<div className="flex gap-2 items-center">
						<TbReportAnalytics className="hidden md:block" />
						<div>Grade</div>
					</div>
				</Button>
			</div>
		</>
	)
}

export default Grader
