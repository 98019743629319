// EditableSpan.js
import { Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import EditableSpanComponent from './EditableNodeView'

const EditableSpan = Node.create({
	name: 'editableSpan',

	group: 'inline',
	inline: true,
	// Remove `selectable` and `atom` properties

	content: 'text*', // Allow the node to contain text content
	priority: 1000, // Higher number means higher priority

	addAttributes() {
		return {
			'data-edited': {
				default: null,
			},
			'data-focus': {
				default: null,
			},
		}
	},

	// @ts-ignore
	parseHTML() {
		return [
			{
				tag: 'span[data-edited][data-focus]',
				getAttrs: (dom) => {
					if (
						dom.hasAttribute('data-edited') &&
						dom.hasAttribute('data-focus') &&
						!dom.hasAttribute('data-hover-menu') // Ensure it's not a hover menu
					) {
						return {
							'data-edited': dom.getAttribute('data-edited'),
							'data-focus': dom.getAttribute('data-focus'),
						}
					}
					return false // Do not match if conditions are not met
				},
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return ['span', HTMLAttributes, 0] // Include content hole
	},

	addNodeView() {
		return ReactNodeViewRenderer(EditableSpanComponent)
	},
})

export default EditableSpan
