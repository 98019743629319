import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import HoverMenuNodeView from './HoverMenuNodeView'

export const HoverMenuNode = Node.create({
	name: 'hoverMenu',

	group: 'inline',

	inline: true,

	content: 'text*',

	priority: 900, // Lower than EditableSpan

	parseHTML() {
		return [
			{
				tag: 'span[data-hover-menu]',
				getAttrs: (dom) => {
					if (
						dom.hasAttribute('data-hover-menu') &&
						!dom.hasAttribute('data-edited') &&
						!dom.hasAttribute('data-focus')
					) {
						return {
							'data-hover-menu': dom.getAttribute('data-hover-menu'),
						}
					}
					return false // Do not match if conditions are not met
				},
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return [
			'span', // Use 'span' for inline elements
			mergeAttributes(HTMLAttributes, { 'data-hover-menu': 'true' }),
			0,
		]
	},

	addNodeView() {
		return ReactNodeViewRenderer(HoverMenuNodeView)
	},
})
