import { useEffect, useState, useMemo } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import Button from '../Buttons'
import { CONSTANTS } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { selectShowNav, setShowNav } from '../../redux/systemSlice'
import { selectDocumentState } from '../../redux/docSlice'
import { useAuth } from '../../contexts/Auth'
import { useIsAuthenticated } from '../../hooks/authenticated'
import { htmlToString } from '../../helpers/utility'
import { DocumentState } from '../../redux/types'
import Detector from './Detector'
import { useDocId } from '../../hooks/docID'
import useEnterApp from '../../hooks/enterApp'
import AccountModal from '../Modals/Account'
import Subscriptions from '../Modals/Subscriptions'
import WordCount from './WordCount'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import LoginModal from '../Modals/Login'
import Grader from './Grader'
import useIsPremium from '../../hooks/useIsPremium'
import ToolCredits from '../ToolCredits'
import SuggestMonthly from '../Modals/SuggestMonthly'
import { FEATURE_FLAGS, useAnnualPlan } from '../../hooks/featureFlags'
import CopyButton from '../Copy'

const baseClassNames = 'flex justify-between items-center mx-auto md:h-auto px-4 py-2 md:py-3 md:px-auto'

function Navbar(props: RouteComponentProps) {
	const [createUserLoading, setCreateUserLoading] = useState(false)
	const [copied, setCopied] = useState(false)
	const [accountModalOpen, setAccountModalOpen] = useState(false)
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const [suggestMonthlyModalOpen, setSuggestMonthlyModalOpen] = useState(false)
	const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
	const [annualPlan, setAnnualPlan] = useAnnualPlan()
	const monthlySuggestionFF = useFeatureFlagVariantKey(FEATURE_FLAGS.MONTHLY_SUGGESTION)
	const searchParamsString = document.location.search
	const dispatch = useDispatch()
	const showNavRedux = useSelector(selectShowNav)
	const docID = useDocId()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const pathname = window.location.pathname
	const posthog = usePostHog()
	const { currentUser } = useAuth() as any
	const isAuthenticated = useIsAuthenticated()
	const { enterApp } = useEnterApp({ history: props.history, setLoading: setCreateUserLoading })
	const [isPremiumPath, setIsPremiumPath] = useState(false)
	const isPremium = useIsPremium()

	const logo = <span className="text-4xl md:text-5xl leading-0 w-0 relative right-1">○</span>

	const searchParams = useMemo(() => {
		let params = new URLSearchParams(searchParamsString)
		return {
			canceled: params.getAll('canceled')[0],
		}
	}, [searchParamsString])

	useEffect(() => {
		if (searchParams.canceled) {
			posthog?.capture('checkout-canceled', { annual: annualPlan })
			if (annualPlan && monthlySuggestionFF === 'test' && !subscriptionModalOpen) {
				setSuggestMonthlyModalOpen(true)
				setAnnualPlan(false)
			} else if (!suggestMonthlyModalOpen && !!monthlySuggestionFF) {
				setSubscriptionModalOpen(true)
			}
		}
	}, [searchParams.canceled, monthlySuggestionFF, annualPlan])

	//Control showing the nav bar for user mouse move and scroll
	useEffect(() => {
		const onMouseMove = () => {
			dispatch(setShowNav({ value: true }))
		}
		const onScroll = () => {
			const documentPathname = document.location.pathname
			if ([CONSTANTS.CREATE_PATH, CONSTANTS.DOC_PATH].includes(documentPathname)) {
				return
			}
			dispatch(setShowNav({ value: true }))
		}

		window.addEventListener('scroll', onScroll)
		window.addEventListener('mousemove', onMouseMove)
		return () => {
			window.removeEventListener('mousemove', onMouseMove)
			window.removeEventListener('scroll', onScroll)
		}
	})

	useEffect(() => {
		if (copied) {
			const timeoutId = setTimeout(() => {
				setCopied(false)
			}, 2000)
			return () => clearTimeout(timeoutId)
		}
	}, [copied])

	const handleCopyClick = () => {
		if (documentState.isGenerating || documentState.isTypingAddMore || documentState.isTypingEssay) return
		setCopied(true)
	}
	const nameAndLogo = (
		<Link to={'/'} className="flex items-center md:gap-2 gap-0">
			<span className="logo">{logo} </span>
			<span className="lowercase text-primary text-lg md:text-[26px] font-semibold">
				<span>
					The Good <span className="text-gray-500 fancy-font font-normal">AI</span>
				</span>
			</span>
		</Link>
	)

	const copyButton = useMemo(() => {
		if (!documentState) return

		return (
			<div className="md:mr-7 mr-8 bottom-1 relative h-6">
				<CopyButton
					textToCopy={htmlToString(documentState.content ?? '')}
					disabled={
						documentState.isGenerating ||
						documentState.isTypingAddMore ||
						documentState.isTypingEssay ||
						documentState.content === CONSTANTS.EMPTY_DOCUMENT ||
						documentState.content === ''
					}
					onCopy={() => posthog?.capture('copy-button-clicked')}
				/>
			</div>
		)
	}, [copied, documentState, posthog])

	const basicNavbar = {
		desktop: (
			<>
				<div
					id="desktopNavbar"
					className={`${baseClassNames} px-4 lg:px-56 max-w-[1800px] basic ` + (showNavRedux ? 'visible' : '')}
				>
					{nameAndLogo}
					<span onClick={() => enterApp()}>
						<Button type="tertiary">
							<span>{createUserLoading ? 'Loading...' : isAuthenticated ? 'Launch App' : 'Use for Free'}</span>
						</Button>
					</span>
				</div>
			</>
		),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} basic ` + (showNavRedux ? 'visible' : '')}>
				{nameAndLogo}
				<span onClick={() => enterApp()}>
					<Button type="tertiary">
						<span>{createUserLoading ? 'Loading...' : isAuthenticated ? 'Launch App' : 'Use for Free'}</span>
					</Button>
				</span>
			</div>
		),
	}

	function docNavbarCTAFunc() {
		if (currentUser) {
			setAccountModalOpen(true)
		} else {
			setLoginModalOpen(true)
		}
	}

	const docNavbar = {
		desktop: (
			<div id="desktopNavbar" className={`${baseClassNames} doc ` + (showNavRedux ? 'visible' : '')}>
				<Link to={'/'}>
					<span className="logo">{logo} </span>
				</Link>
				<div className="flex items-center gap-2">
					<Button type="tertiary" onClick={docNavbarCTAFunc}>
						<span>{currentUser ? 'ACCOUNT' : 'LOG IN'}</span>
					</Button>
					{/* {!currentUser && (
						<Button type="tertiary" onClick={docNavbarCTAFunc}>
							<span>SIGN UP</span>
						</Button>
					)} */}
				</div>
			</div>
		),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} doc ` + (showNavRedux ? 'visible' : '')}>
				<div className="flex justify-between w-full items-center">
					<Link to={'/'}>
						<span className="logo">{logo}</span>
					</Link>
					<div className="flex items-center gap-2">
						<Button type="tertiary" onClick={docNavbarCTAFunc}>
							<span>{currentUser ? 'ACCOUNT' : 'LOG IN'}</span>
						</Button>
						{/* {!currentUser && (
							<Button type="tertiary" className="hover:bg-secondary" onClick={docNavbarCTAFunc}>
								<span>SIGN UP</span>
							</Button>
						)} */}
					</div>
				</div>
			</div>
		),
	}

	const newDocNavbar = {
		desktop: (
			<div id="desktopNavbar" className={`${baseClassNames} doc ` + (showNavRedux ? 'visible' : '')}>
				<Link to={'/'}>
					<span className="logo">{logo} </span>
				</Link>
			</div>
		),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} doc ` + (showNavRedux ? 'visible' : '')}>
				<div className="flex justify-between w-full items-center">
					<Link to={'/'}>
						<span className="logo">{logo}</span>
					</Link>
				</div>
			</div>
		),
	}

	const createNavbar = {
		desktop: (
			<div
				id="desktopNavbar"
				className={
					`${baseClassNames} group transition-opacity duration-500 create ` +
					(showNavRedux ? 'opacity-100' : 'opacity-0')
				}
			>
				<Link to={CONSTANTS.DOC_PATH} className="h-8 overflow-hidden">
					<span className="text-[55px] h-16 rotate-180 inline-block relative">⤑</span>
				</Link>
				{documentState && documentState.setup && !documentState.isGenerating && (
					<>
						<div className="desktopNavRight flex items-center">
							<span className="text-gray-600 mr-8 opacity-0 group-hover:opacity-100 transition-opacity">
								Saves automatically
							</span>
							{!documentState.isGenerating && <WordCount />}
							{copyButton}
							<div className="mr-4">
								<Grader />
							</div>
							{isPremium && <Detector />}
							{!isPremium && (
								<Button
									type="tertiary"
									onClick={() => {
										setSubscriptionModalOpen(true)
										posthog?.capture('nav-upgrade-button-clicked')
										setIsPremiumPath(true)
									}}
								>
									Upgrade
								</Button>
							)}
						</div>
					</>
				)}
			</div>
		),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} create `}>
				<div className="flex justify-between w-full items-center">
					<Link to={CONSTANTS.DOC_PATH} className="h-8 overflow-hidden">
						<span className="text-[55px] h-16 rotate-180 inline-block relative">⤑</span>
					</Link>
					{documentState?.setup && isPremium && !documentState?.isGenerating && (
						<div className="flex md:gap-3 gap-1">
							{isPremium && copyButton}
							<Grader />
							{isPremium && <Detector />}
						</div>
					)}
					{!isPremium && documentState && documentState.setup && !documentState.isGenerating && (
						<div className="flex gap-2">
							{copyButton}
							<Grader />
							<Button
								type="tertiary"
								onClick={() => {
									setSubscriptionModalOpen(true)
									posthog?.capture('nav-upgrade-button-clicked')
									setIsPremiumPath(true)
								}}
								className="px-2"
							>
								Upgrade
							</Button>
						</div>
					)}
				</div>
			</div>
		),
	}

	const toolNavbar = {
		desktop: (
			<div
				id="desktopNavbar"
				className={
					`${baseClassNames} group transition-opacity duration-500 create ` +
					(showNavRedux ? 'opacity-100' : 'opacity-0')
				}
			>
				<Link to={CONSTANTS.DOC_PATH} className="h-8 overflow-hidden">
					<span className="text-[55px] h-16 rotate-180 inline-block relative">⤑</span>
				</Link>
				{documentState && documentState.setup && !documentState.isGenerating && (
					<>
						<div className="desktopNavRight flex items-center gap-3">
							<ToolCredits />
							{!isPremium && (
								<Button
									type="tertiary"
									onClick={() => {
										setSubscriptionModalOpen(true)
										posthog?.capture('nav-upgrade-button-clicked')
										setIsPremiumPath(true)
									}}
								>
									Upgrade
								</Button>
							)}
						</div>
					</>
				)}
			</div>
		),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} create `}>
				<div className="flex justify-between w-full items-center">
					<Link to={CONSTANTS.DOC_PATH} className="h-8 overflow-hidden">
						<span className="text-[55px] h-16 rotate-180 inline-block relative">⤑</span>
					</Link>
					{!isPremium && documentState && documentState.setup && !documentState.isGenerating && (
						<Button
							type="tertiary"
							onClick={() => {
								setSubscriptionModalOpen(true)
								posthog?.capture('nav-upgrade-button-clicked')
								setIsPremiumPath(true)
							}}
							className="px-2"
						>
							Upgrade
						</Button>
					)}
				</div>
			</div>
		),
	}

	const navigation = [
		{ path: '/examples', nav: basicNavbar },
		{ path: '/examples/', nav: basicNavbar },
		{ path: CONSTANTS.DOC_PATH, nav: docNavbar },
		{ path: CONSTANTS.CREATE_PATH, nav: createNavbar },
		{ path: '/humanizer', nav: toolNavbar },
		{ path: '/detector', nav: toolNavbar },
		{ path: '/new', nav: newDocNavbar },
		{ path: '/', nav: basicNavbar },
	]

	let navBar = basicNavbar

	for (let i = 0; i < navigation.length; i++) {
		if (pathname.includes(navigation[i].path)) {
			navBar = navigation[i].nav
			break
		}
	}

	return (
		<>
			<Subscriptions
				open={subscriptionModalOpen}
				closeModal={() => {
					setSubscriptionModalOpen(false)
				}}
				setLoginModal={(open: boolean) => {
					setLoginModalOpen(open)
					if (open) {
						setIsPremiumPath(true)
					}
				}}
				annualPlan={annualPlan}
				setAnnualPlan={setAnnualPlan}
			/>
			<AccountModal isOpen={accountModalOpen} closeModal={() => setAccountModalOpen(false)} />
			<LoginModal
				open={loginModalOpen}
				setModalOpen={setLoginModalOpen}
				premiumPath={isPremiumPath}
				annualPlan={annualPlan}
			/>
			<SuggestMonthly
				open={suggestMonthlyModalOpen}
				closeModal={() => {
					setSuggestMonthlyModalOpen(false)
				}}
			/>
			<div className="bg-white w-full fixed top-0 z-20">
				<div className="block md:hidden">{navBar.mobile}</div>
				<div className="hidden md:block">{navBar.desktop}</div>
			</div>
		</>
	)
}

export default withRouter(Navbar)
