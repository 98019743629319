import { useAtom } from 'jotai'
import { editorAtom } from './constants'

const ScoreIndicator = ({
	score,
	borderColor = 'border-gray-900',
	isExampleIndicator = false,
}: {
	score: number
	borderColor?: string
	isExampleIndicator?: boolean
}) => {
	const [editorState] = useAtom(editorAtom)

	const getDotColor = (score: number) => {
		if (editorState.aiDetectionScore === undefined && !isExampleIndicator) {
			return 'transparent'
		}
		if (score < 50) {
			return 'bg-success-default'
		} else if (score < 80) {
			return 'bg-warning-default'
		}
		return 'bg-danger-default'
	}

	return <div className={`rounded-full w-4 h-4 ${borderColor} border ${getDotColor(score)}`} />
}

export default ScoreIndicator
