import { useCookies } from 'react-cookie'
import { useCookieUser } from './cookieUser'
import { useCallback, useEffect, useState } from 'react'
import { useAuth } from '../contexts/Auth'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser, selectUserPagesCache, setUserPagesCache } from '../redux/systemSlice'
import { getCookie } from '../App'
import { CONSTANTS } from '../constants'
import * as Sentry from '@sentry/react'
import { useHistory } from 'react-router-dom'

const useNewDocument = () => {
	const { userIDCookie } = useCookieUser()
	const [cookies, setCookie] = useCookies(['docs'])
	const [loading, setLoading] = useState(false)
	const [created, setCreated] = useState(false)
	const [templateID, setTemplateID] = useState<string | undefined>()
	const [docNeedsCreation, setDocNeedsCreation] = useState(false)
	const { currentUser } = useAuth() as any
	const user = useSelector(selectUser)
	const userPagesCache = useSelector(selectUserPagesCache)
	const history = useHistory()

	const dispatch = useDispatch()

	const addDocToCookie = useCallback(
		(id: number) => {
			const existingDocs = getCookie('docs')
			if (existingDocs && existingDocs.length) {
				setCookie('docs', JSON.stringify([...JSON.parse(existingDocs), id]))
			} else {
				setCookie('docs', JSON.stringify([id]))
			}
		},
		[setCookie]
	)

	const createDocCallback = useCallback(
		async (templateID: string | undefined) => {
			setLoading(true)
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
				},
				body: JSON.stringify({ template: templateID, userId: user.id ?? userIDCookie }),
			}
			fetch(process['env']['REACT_APP_API_ROOT'] + '/document/create/', requestOptions)
				.then((res) => res.json())
				.then((result: { id: number } | undefined) => {
					if (!currentUser && result?.id) {
						addDocToCookie(result.id)
					}
					if (result?.id) {
						setCreated(true)
						setLoading(false)
						dispatch(setUserPagesCache({ ...userPagesCache, dirty: true }))
						history.push(CONSTANTS.CREATE_PATH + '/' + templateID + '/' + btoa(result.id.toString()))
					}
				})
				.catch((e) => {
					Sentry.captureException(e)
				})
		},
		[addDocToCookie, currentUser, dispatch, history, user.id, userIDCookie, userPagesCache]
	)

	const createDoc = useCallback((templateID: string | undefined) => {
		setTemplateID(templateID)
		setDocNeedsCreation(true)
	}, [])

	useEffect(() => {
		if ((userIDCookie || user.id) && templateID && cookies && !created && !loading) {
			createDocCallback(templateID)
			setDocNeedsCreation(false)
		}
	}, [docNeedsCreation, createDocCallback, templateID, userIDCookie, user.id, cookies, created, loading])

	return { createDoc, loading, templateID }
}

export default useNewDocument
