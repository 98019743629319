import { useCallback, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import {
	selectUser,
	selectUserLoading,
	selectUserPagesCache,
	selectUserPagesLoading,
	setUserPagesCache,
	setUserPagesLoading,
} from '../../redux/systemSlice'
import * as Sentry from '@sentry/react'
import { useAuth } from '../../contexts/Auth'
import { Skeleton, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { CONSTANTS } from '../../constants'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import './_UserDocs.scss'
import Delete from '../Modals/Delete'
import ExistingDocsModal from '../Modals/Docs'
import { FaRegTrashAlt } from 'react-icons/fa'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'
import { formatDistanceToNow } from 'date-fns'
import { FaArrowRight } from 'react-icons/fa6'

export type ExistingDoc = {
	title: string | null
	template: 0 | 1 | 2
	created: Date
	id: number
}

const UserDocs = () => {
	const [userDocs, setUserDocs] = useState<ExistingDoc[]>([])
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const [deleteDocId, setDeleteDocId] = useState(0)
	const [deleteDocTitle, setDeleteDocTitle] = useState('')
	const [existingDocsModalOpen, setExistingDocsModalOpen] = useState(false)
	const [cookies] = useCookies(['docs'])
	const userLoading = useSelector(selectUserLoading)
	const userPagesCache = useSelector(selectUserPagesCache)
	const userPagesLoading = useSelector(selectUserPagesLoading)
	const { currentUser } = useAuth() as any
	const user = useSelector(selectUser)
	const dispatch = useDispatch()
	const redesignedDashboardFF = useFeatureFlagVariantKey(FEATURE_FLAGS.REDESIGNED_DASHBOARD)

	const getCookieDocuments = useCallback(
		async (existingCookieDocs: any) => {
			dispatch(setUserPagesLoading({ value: true }))
			if (userPagesCache.source === 'cookie' && !userPagesCache.dirty) {
				setUserDocs(userPagesCache.pages)
				dispatch(setUserPagesLoading({ value: false }))
				return
			}
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ docs: existingCookieDocs }),
			}
			fetch(process['env']['REACT_APP_API_ROOT'] + '/document/guest-list/', requestOptions)
				.then((res) => res.json())
				.then((result: ExistingDoc[]) => {
					if (result) {
						setUserDocs(result)
						dispatch(setUserPagesCache({ source: 'cookie', pages: result, dirty: false }))
					}
					dispatch(setUserPagesLoading({ value: false }))
				})
				.catch((e) => {
					Sentry.captureException(e)
					dispatch(setUserPagesLoading({ value: false }))
				})
		},
		[dispatch, userPagesCache]
	)

	const getUserDocuments = useCallback(async () => {
		if (currentUser && user.id) {
			dispatch(setUserPagesLoading({ value: true }))
			if (userPagesCache.source === 'auth' && !userPagesCache.dirty) {
				setUserDocs(userPagesCache.pages)
				dispatch(setUserPagesLoading({ value: false }))
				return
			}
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
				},
				body: JSON.stringify({
					userId: user.id,
				}),
			}
			fetch(process['env']['REACT_APP_API_ROOT'] + '/document/list/', requestOptions)
				.then((res) => res.json())
				.then((result: ExistingDoc[]) => {
					if (result) {
						setUserDocs(result)
						dispatch(setUserPagesCache({ source: 'auth', pages: result, dirty: false }))
					}
					dispatch(setUserPagesLoading({ value: false }))
				})
				.catch((e) => {
					Sentry.captureException(e)
					dispatch(setUserPagesLoading({ value: false }))
				})
		}
	}, [currentUser, dispatch, userPagesCache, user])

	useEffect(() => {
		if (userLoading) {
			return
		}
		let existingCookieDocs = cookies.docs
		if (existingCookieDocs && existingCookieDocs.length && !currentUser) {
			getCookieDocuments(existingCookieDocs)
		} else if (existingCookieDocs && !currentUser) {
			setUserDocs([])
		} else if (currentUser) {
			getUserDocuments()
		}
	}, [currentUser, user, cookies.docs, getCookieDocuments, getUserDocuments, userLoading, dispatch])

	// if (!redesignedDashboardFF) {
	// 	return <></>
	// }

	if (redesignedDashboardFF === 'test') {
		return (
			<>
				{!!deleteDocId && (
					<Delete
						isOpen={deleteModalOpen}
						id={deleteDocId}
						title={deleteDocTitle}
						closeModal={() => {
							setDeleteModalOpen(false)
							setDeleteDocId(0)
							setDeleteDocTitle('')
						}}
					/>
				)}
				<ExistingDocsModal isOpen={existingDocsModalOpen} closeModal={() => setExistingDocsModalOpen(false)} />
				<div className="flex flex-col gap-2 mt-12">
					<div className="flex justify-between items-center pb-3 border-b border-gray-700 gap-2">
						<div className="text-primary font-semibold text-xl">Your Recent Documents</div>
						<div
							className="text-gray-700 font-semibold cursor-pointer hover:text-black"
							onClick={() => setExistingDocsModalOpen(true)}
						>
							<div className="flex gap-2 items-center group whitespace-nowrap mr-1 md:mr-0">
								View all
								<FaArrowRight className="group-hover:left-1 left-0 transition-all relative" />
							</div>
						</div>
					</div>
					{userPagesLoading ? (
						[0, 1, 2].map((index) => (
							<Skeleton variant="rectangular" key={index} height={26} width="100%" style={{ borderRadius: '8px' }} />
						))
					) : userDocs.length ? (
						<div className="flex flex-col gap-2">
							{userDocs.slice(0, 10).map((doc: ExistingDoc, index: number) => {
								return (
									<Link
										to={CONSTANTS.CREATE_PATH + '/' + doc.template + '/' + btoa(doc.id.toString())}
										key={index + 'doc'}
										className="px-3 py-1 hover:bg-gray-100 rounded cursor-pointer flex justify-between items-center text-gray-900 transition-colors group font-semibold gap-2"
									>
										<span>
											{!!doc?.title?.length
												? doc.title.length > 90
													? doc.title.slice(0, 90) + '...'
													: doc.title
												: 'Untitled'}
										</span>
										<div className="flex gap-3 items-center">
											<div className="whitespace-nowrap">
												{formatDistanceToNow(new Date(doc.created), { addSuffix: true }).replace('about ', '')}
											</div>
											<span
												className="md:block hidden group-hover:visible invisible group-hover:opacity-100 opacity-0 transition-all"
												onClick={(e) => {
													e.preventDefault()
													setDeleteDocId(doc.id)
													setDeleteDocTitle(doc.title ?? '')
													setDeleteModalOpen(true)
												}}
											>
												<FaRegTrashAlt className="text-sm" />
											</span>
										</div>
									</Link>
								)
							})}
						</div>
					) : (
						<div className="text-gray-700 mt-3">No recent documents</div>
					)}
				</div>
			</>
		)
	}

	if (userPagesLoading) {
		return (
			<Stack direction="row" spacing={1} paddingTop="10px">
				{[0, 1, 2].map((index) => (
					<Skeleton variant="rectangular" key={index} height={26} width={100} style={{ borderRadius: '17px' }} />
				))}
			</Stack>
		)
	}
	if (!userDocs.length) {
		return <></>
	}
	return (
		<>
			{!!deleteDocId && (
				<Delete
					isOpen={deleteModalOpen}
					id={deleteDocId}
					title={deleteDocTitle}
					closeModal={() => {
						setDeleteModalOpen(false)
						setDeleteDocId(0)
						setDeleteDocTitle('')
					}}
				/>
			)}
			<ExistingDocsModal isOpen={existingDocsModalOpen} closeModal={() => setExistingDocsModalOpen(false)} />
			<div className="existingDocsHeader flex flex-row overflow-x-auto w-100 py-3 flex-nowrap mb-0 gap-2">
				{userDocs.length > 3 && (
					<div
						className="viewAll flex items-center whitespace-nowrap border border-primary hover:bg-secondary text-primary cursor-pointer px-3 rounded-full leading-snug"
						onClick={() => setExistingDocsModalOpen(true)}
					>
						<FormatListBulletedIcon fontSize="inherit" className="mr-2" />
						<div>View all</div>
					</div>
				)}
				<div className="flex gap-2 overflow-x-auto overflow-y-hidden">
					{userDocs.map((doc: ExistingDoc, index: number) => {
						return (
							<Link
								to={CONSTANTS.CREATE_PATH + '/' + doc.template + '/' + btoa(doc.id.toString())}
								key={index + 'doc'}
								className="relative group border border-primary border-dotted hover:border-solid hover:bg-secondary text-primary cursor-pointer px-3 py-0.5 hover:pr-8 rounded-full whitespace-nowrap leading-snug"
							>
								<span
									className="h-7 w-7 border border-primary rounded-full cursor-pointer hover:bg-primary hover:text-white text-gray-700 bg-white absolute right-[-1px] top-[-1px] hidden group-hover:flex items-center justify-center"
									onClick={(e) => {
										e.preventDefault()
										setDeleteDocId(doc.id)
										setDeleteDocTitle(doc.title ?? '')
										setDeleteModalOpen(true)
									}}
								>
									<FaRegTrashAlt className="text-xs" />
								</span>
								<span>
									{!!doc?.title?.length
										? doc.title.length > CONSTANTS.MAX_DOCUMENT_DISPLAY_LENGTH
											? doc.title.slice(0, CONSTANTS.MAX_DOCUMENT_DISPLAY_LENGTH) + '...'
											: doc.title
										: 'Untitled'}
								</span>
							</Link>
						)
					})}
				</div>
			</div>
		</>
	)
}

export default UserDocs
