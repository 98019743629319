import { useDispatch, useSelector } from 'react-redux'
import { DocumentState } from '../../redux/types'
import { selectDocumentState, setUserWriterSelections } from '../../redux/docSlice'
import { TbSparkles } from 'react-icons/tb'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import { useState } from 'react'
import Modal from '../Modals/Modal'
import { EditorContent, useEditor } from '@tiptap/react'
import Placeholder from '@tiptap/extension-placeholder'
import Button from '../Buttons'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'

const CustomWritingStyleModal = ({
	docID,
	modalOpen,
	setModalOpen,
}: {
	docID: string
	modalOpen: boolean
	setModalOpen: (open: boolean) => void
}) => {
	const [loading, setLoading] = useState(false)
	const editor = useEditor({
		extensions: [
			Document,
			Text,
			Paragraph,
			Placeholder.configure({
				placeholder: 'Paste your sample text here...',
			}),
		],
		content: '',
	})
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const dispatch = useDispatch()

	const generateCustomWritingStyle = () => {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ referenceText: editor?.getText() }),
		}
		setLoading(true)

		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/generate-writing-style/', requestOptions)
			.then((res) => res.json())
			.then((response: { shortDescription: string; longDescription: string }) => {
				setLoading(false)
				setModalOpen(false)
				const text = editor?.getText().split(' ').slice(0, 500).join(' ')
				dispatch(
					setUserWriterSelections({
						docID: docID,
						userWriterSelections: {
							...documentState.userWriterSelections,
							writingStyle: {
								referenceText: text,
								description: response.shortDescription,
								longDescription: response.longDescription,
								generalSelected: false,
							},
						},
					})
				)
			})
			.catch((err) => {
				console.error(err)
			})
	}

	return (
		<Modal open={modalOpen} closeModal={() => setModalOpen(false)} portalClassName="customWritingStyle">
			<div className="flex flex-col gap-4 mt-5">
				<div className="flex flex-col gap-1">
					<div className="text-xl font-semibold text-center">Add a custom writing style</div>
					<div className="text-sm text-gray-800 text-center">
						Paste a sample of your writing below to create a personalized AI writing style that captures your unique
						voice and tone.
					</div>
				</div>
				<div className="flex flex-col gap-4">
					<div>
						<EditorContent
							editor={editor}
							id="custom-style-editor"
							className="border border-gray-500 rounded max-h-96 overflow-auto"
							placeholder="Paste your sample text here..."
						/>
						<div className="text-sm text-gray-700">Minimum 20 words</div>
					</div>
					<Button
						type="secondary"
						emphasize
						className="border border-gray-600 text-lg"
						disabled={(editor?.getText().split('').length ?? 0) < 20}
						onClick={() => {
							generateCustomWritingStyle()
						}}
					>
						{loading ? (
							'Thinking...'
						) : documentState.userWriterSelections.writingStyle.description ? (
							<span>Save changes</span>
						) : (
							<span>Generate Style</span>
						)}
					</Button>
				</div>
			</div>
		</Modal>
	)
}

const WritingStyleSelection = ({ docID, omitHeader = false }: { docID: string; omitHeader?: boolean }) => {
	const [modalOpen, setModalOpen] = useState(false)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const dispatch = useDispatch()
	const redesignedSetupV2FF = useFeatureFlagVariantKey(FEATURE_FLAGS.REDESIGNED_SETUP_V2)
	const steppedEssaySetupFF = useFeatureFlagVariantKey(FEATURE_FLAGS.STEPPED_ESSAY_SETUP)
	if (!documentState.userWriterSelections.writingStyle) {
		return null
	}
	const generalSelected = !!documentState.userWriterSelections.writingStyle?.generalSelected
	const customWritingStyleGenerated = !!documentState.userWriterSelections.writingStyle.referenceText

	return (
		<>
			<CustomWritingStyleModal docID={docID} modalOpen={modalOpen} setModalOpen={setModalOpen} />
			<div className="flex flex-col gap-4 mt-10">
				{!omitHeader && (
					<div
						className={`text-xl font-semibold ${
							redesignedSetupV2FF === 'test' || steppedEssaySetupFF === 'test' ? '' : 'text-center'
						}`}
					>
						{redesignedSetupV2FF === 'test' || steppedEssaySetupFF === 'test'
							? 'Writing Style'
							: 'Select a writing style'}
					</div>
				)}
				<div
					className={`grid grid-cols-[1fr,1fr] ${
						redesignedSetupV2FF === 'test'
							? 'gap-4'
							: steppedEssaySetupFF === 'test'
							? 'gap-8'
							: 'md:grid-cols-[1fr,80px,1fr] gap-2'
					} justify-center flex-wrap`}
				>
					<div
						className={` cursor-pointer border ${
							redesignedSetupV2FF === 'test' ? 'p-4 pt-3' : 'px-6 py-4'
						} rounded border-gray-600 flex flex-col hover:shadow-md  ${
							generalSelected ? 'bg-secondary' : 'border-dotted'
						}`}
						onClick={() => {
							dispatch(
								setUserWriterSelections({
									docID: docID,
									userWriterSelections: {
										...documentState.userWriterSelections,
										writingStyle: {
											...documentState.userWriterSelections.writingStyle,
											generalSelected: true,
										},
									},
								})
							)
						}}
					>
						<div className="text-lg font-semibold">Basic</div>
						<div className="text-sm text-gray-800">College reading level with an academic tone.</div>
					</div>
					{redesignedSetupV2FF !== 'test' && steppedEssaySetupFF !== 'test' && (
						<div className="text-gray-800 mt-8 md:block hidden text-center">Or</div>
					)}
					<div
						className={`cursor-pointer border ${
							redesignedSetupV2FF === 'test' ? 'p-4 pt-3' : 'px-6 py-4'
						} rounded border-gray-600 flex flex-col hover:shadow-md ${
							!generalSelected ? 'bg-secondary' : 'border-dotted'
						}`}
						onClick={() => {
							setModalOpen(true)
						}}
					>
						<div className="flex gap-1 items-center">
							{customWritingStyleGenerated && <TbSparkles />}
							<div className="text-lg font-semibold">
								{customWritingStyleGenerated ? 'Your Style' : 'Custom Writing Style'}
							</div>
						</div>
						<div className="text-sm text-gray-800">
							{customWritingStyleGenerated
								? documentState.userWriterSelections.writingStyle?.description
								: 'Paste in a sample text to personalize your writing style.'}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default WritingStyleSelection
