import { useDispatch, useSelector } from 'react-redux'
import { selectDocumentState, setUserWriterSelections } from '../../redux/docSlice'
import { DocumentState, REF_STYLES } from '../../redux/types'
import { Tooltip } from '@mui/material'
import ReferencesDropdown from './ReferencesDropdown'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'
import OptionDropdown from './OptionDropdown'

const ReferencesSelection = ({ docID }: { docID: string }) => {
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const dispatch = useDispatch()
	const redesignedSetupV2FF = useFeatureFlagVariantKey(FEATURE_FLAGS.REDESIGNED_SETUP_V2)

	if (redesignedSetupV2FF === 'test') {
		let options = [null, ...Object.keys(REF_STYLES)]

		return (
			<>
				<div className={`text-xl mb-3 font-semibold mt-8`}>References</div>
				<OptionDropdown
					options={options}
					selectedOption={documentState.userWriterSelections?.refStyle}
					setSelectedOption={(option) => {
						dispatch(
							setUserWriterSelections({
								docID: docID,
								userWriterSelections: { refStyle: option },
							})
						)
					}}
					displayNames={{
						None: 'None',
						APA_7: 'APA 7 (default)',
						MLA_9: 'MLA 9',
						HARVARD: 'Harvard',
						IEEE: 'IEEE',
						CHICAGO: 'Chicago',
					}}
				/>
			</>
		)
	}

	return (
		<div
			className="flex items-center justify-between mt-10"
			onClick={(e) => {
				const includeRefs =
					documentState && documentState.userWriterSelections && !!documentState?.userWriterSelections?.refStyle

				dispatch(
					setUserWriterSelections({
						docID: docID,
						userWriterSelections: {
							includeRefs: includeRefs,
						},
					})
				)
			}}
		>
			<Tooltip
				title="The AI model cannot ensure all references are accurate. Additionally, the model will not always use outside sources when constructing your essay."
				placement="top-start"
			>
				<div id="refsLabel" className="md:text-2xl text-xl font-semibold">
					References Style:
				</div>
			</Tooltip>
			<ReferencesDropdown docID={docID} />
		</div>
	)
}

export default ReferencesSelection
