import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { setLastAggregatePageVisited } from '../redux/systemSlice'
import { DOC_TEMPLATES } from '../constants'
import UserDocs from '../Components/UserDocs/UserDocs'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import useNewDocument from '../hooks/useNewDocument'
import { Link, useHistory } from 'react-router-dom'
import { LuFileSearch, LuSearchCheck } from 'react-icons/lu'
import { FEATURE_FLAGS } from '../hooks/featureFlags'
import { TbPencilMinus } from 'react-icons/tb'
import { FaListOl, FaRegFile } from 'react-icons/fa6'
import { RiRobot2Line } from 'react-icons/ri'
import EssayImg from '../assets/images/essay.svg'
import Button from '../Components/Buttons'
import { PiClipboardText, PiHeadCircuit } from 'react-icons/pi'
import { IoDocumentOutline } from 'react-icons/io5'
import { BiChevronRight } from 'react-icons/bi'
import UpgradeModals from '../Components/Modals/Upgrade'
import useIsPremium from '../hooks/useIsPremium'
import Loader from '../Components/Loader'

export type templateType = {
	id: string
	title: string
	icon?: JSX.Element
	desc: string
	label?: string
	href?: string
}

export const useOptions = (): templateType[] => {
	const idToDesc = {
		[DOC_TEMPLATES.Writer]: 'Choose a topic, word count, tone, reading level, and more.',
		[DOC_TEMPLATES.Outline]: "Choose a topic and we'll generate an outline for you to complete.",
		[DOC_TEMPLATES.Blank]: 'Start yourself and use our autocomplete tool to guide you.',
		humanizer: 'Paste in text and we will rewrite it to avoid AI detection.',
		detector: 'Paste in text and we will tell you if it was written by AI.',
	}

	return [
		{
			id: DOC_TEMPLATES.Writer,
			title: 'AI Essay Writer',
			icon: <TbPencilMinus size={32} className="mb-2 block" />,
			desc: idToDesc[DOC_TEMPLATES.Writer],
		},
		{
			id: 'humanizer',
			title: 'AI Humanizer',
			icon: <RiRobot2Line size={32} className="mb-2 block" />,
			desc: idToDesc['humanizer'],
			href: '/humanizer',
		},
		{
			id: 'detector',
			title: 'AI Detector',
			icon: <LuFileSearch size={32} className="mb-2 block" />,
			desc: idToDesc['detector'],
			href: '/detector',
		},
		{
			id: DOC_TEMPLATES.Outline,
			title: 'AI Essay Outliner',
			icon: <FaListOl size={26} className="mb-1 block" />,
			desc: idToDesc[DOC_TEMPLATES.Outline],
		},
		{
			id: DOC_TEMPLATES.Blank,
			title: 'Blank Document',
			icon: <FaRegFile size={32} className="mb-2 block" />,
			desc: idToDesc[DOC_TEMPLATES.Blank],
		},
	]
}

const pageName = 'doc'

function Doc() {
	const redesignedDashboardFF = useFeatureFlagVariantKey(FEATURE_FLAGS.REDESIGNED_DASHBOARD)
	const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const isPremium = useIsPremium()
	const dispatch = useDispatch()
	const posthog = usePostHog()
	const { createDoc } = useNewDocument()
	const options = useOptions()
	const history = useHistory()

	useEffect(() => {
		dispatch(setLastAggregatePageVisited({ value: '/choose' }))
	}, [dispatch])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const accountModals = useMemo(
		() => (
			<>
				<UpgradeModals
					subscriptionModalOpen={subscriptionModalOpen}
					loginModalOpen={loginModalOpen}
					setSubscriptionModalOpen={(open: boolean) => {
						setSubscriptionModalOpen(open)
					}}
					setLoginModalOpen={(open: boolean) => {
						setLoginModalOpen(open)
					}}
					premiumPath={true}
				/>
			</>
		),
		[subscriptionModalOpen, loginModalOpen]
	)

	const templateOptions = useMemo(() => {
		return (
			<div>
				{options.map((item, index) => {
					return (
						<Link
							className="mb-6 py-4 px-4 border border-gray-300 bg-gray-50 hover:bg-gray-100 rounded cursor-pointer flex justify-between items-center text-gray-900 transition-colors group template-options"
							to={item.href ?? '/new/' + item.id}
							key={'option' + index}
							onClick={() => {
								posthog.capture('template-chosen', { type: item.title })
							}}
						>
							<div className="flex flex-col gap-2">
								<h5 className="md:text-3xl text-2xl">
									<div className="flex items-center gap-3">{item.title}</div>
									<span className="fancy-font">{item.label}</span>
								</h5>
								<p className="m-0 text-gray-700">{item.desc}</p>
							</div>
							<span className="relative bottom-1 transition-all text-5xl opacity-0 group-hover:opacity-100 group-hover:right-0 right-5">
								⤑
							</span>
						</Link>
					)
				})}
			</div>
		)
	}, [posthog, createDoc, options])

	const greeting = useMemo(() => {
		const now = new Date()
		const currentHour = now.getHours()
		if (currentHour < 12) {
			return 'Good morning'
		} else if (currentHour < 18) {
			return 'Good afternoon'
		} else {
			return 'Good evening'
		}
	}, [])

	if (!redesignedDashboardFF) {
		return (
			<div className="h-screen flex justify-center items-center">
				<Loader />
			</div>
		)
	}

	if (redesignedDashboardFF === 'test') {
		const ButtonWithArrow = ({ children, ...props }: any) => {
			return (
				<Button {...props} className="flex items-center group hover:pr-5 pr-0 transition-all">
					{children}
					<span className="text-2xl h-4 w-4 group-hover:visible invisible group-hover:opacity-100 relative right-2 bottom-1 group-hover:-right-1 opacity-0 transition-all">
						<BiChevronRight />
					</span>
				</Button>
			)
		}

		return (
			<main id={pageName} className="px-2 pt-16 pb-36 max-w-[980px] mx-auto">
				{accountModals}
				{!isPremium && (
					<div className="flex justify-center">
						<div
							className="flex gap-2 px-3 py-1 text-sm font-semibold mt-2 mb-8 mx-auto cursor-pointer bg-gray-200 border rounded-lg border-gray-400"
							onClick={() => {
								setSubscriptionModalOpen(true)
								posthog?.capture('nav-upgrade-button-clicked')
							}}
						>
							Using Limited Free Plan <span className="text-link">Upgrade</span>
						</div>
					</div>
				)}
				<h5 className="md:text-4xl text-center mb-10 mt-3 text-3xl">{greeting}, how can I help you write?</h5>
				<div className="bg-secondary rounded-lg p-6 flex md:flex-nowrap flex-wrap gap-5 mt-6 items-center border border-gray-400">
					<img src={EssayImg} alt="Essaybot AI Writer" className="h-32" />
					<div className="flex flex-col gap-2 items-start">
						<div className="text-3xl font-semibold">Generate an essay</div>
						<div className="text-lg">
							Generate an essay, research paper, or any other type of content in seconds, with in-text citations and AI
							bypass.
						</div>
						<ButtonWithArrow
							type="default"
							className="text-lg"
							onClick={() => history.push('/new/' + DOC_TEMPLATES.Writer)}
						>
							Start generating now
						</ButtonWithArrow>
					</div>
				</div>
				<div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
					<div className="rounded-lg p-5 flex flex-col gap-4 items-start border border-gray-400">
						<PiHeadCircuit size={48} className="transform -scale-x-100" />
						<div className="flex flex-col gap-4 items-start grow">
							<div className="flex flex-col gap-1">
								<div className="text-xl font-semibold">Humanize your AI-written text</div>
								<div className="text-lg text-gray-700">with our AI Detector Bypass Tool</div>
							</div>
							<div className="flex flex-col grow justify-end">
								<ButtonWithArrow type="default" onClick={() => history.push('/humanizer')}>
									Humanize now
								</ButtonWithArrow>
							</div>
						</div>
					</div>
					<div className="rounded-lg p-5 flex flex-col gap-4 items-start border border-gray-400">
						<LuSearchCheck size={48} />
						<div className="flex flex-col gap-4 items-start grow">
							<div className="flex flex-col gap-1">
								<div className="text-xl font-semibold">Check for AI-written text</div>
								<div className="text-lg text-gray-700">using our AI Detector</div>
							</div>
							<div className="flex flex-col grow justify-end">
								<ButtonWithArrow type="default" onClick={() => history.push('/detector')}>
									Run an AI check
								</ButtonWithArrow>
							</div>
						</div>
					</div>
					<div className="rounded-lg p-5 flex flex-col gap-4 items-start border border-gray-400">
						<PiClipboardText size={48} className="transform -scale-x-100" />
						<div className="flex flex-col gap-4 items-start grow">
							<div className="flex flex-col gap-1">
								<div className="text-xl font-semibold">Generate a thesis and essay outline</div>
								<div className="text-lg text-gray-700">using our AI Outliner</div>
							</div>
							<div className="flex flex-col grow justify-end">
								<ButtonWithArrow type="default" onClick={() => history.push('/new/' + DOC_TEMPLATES.Outline)}>
									Generate outline
								</ButtonWithArrow>
							</div>
						</div>
					</div>
					<div className="rounded-lg p-5 flex flex-col gap-4 items-start border border-gray-400">
						<IoDocumentOutline size={46} className="transform -scale-x-100" />
						<div className="flex flex-col gap-4 items-start grow">
							<div className="flex flex-col gap-1">
								<div className="text-xl font-semibold">Start from a blank document</div>
								<div className="text-lg text-gray-700">using our AI writing Copilot</div>
							</div>
							<div className="flex flex-col grow justify-end">
								<ButtonWithArrow type="default" onClick={() => history.push('/new/' + DOC_TEMPLATES.Blank)}>
									Start writing
								</ButtonWithArrow>
							</div>
						</div>
					</div>
				</div>
				<UserDocs />
			</main>
		)
	}

	return (
		<>
			<Helmet>
				<title>Choose an Option</title>
			</Helmet>
			<main id={pageName} className="px-2 lg:px-56 pt-16 pb-36 max-w-[1400px] mx-auto">
				<div className="mb-6">
					<UserDocs />
				</div>
				<h5 className="md:text-4xl text-center my-3 text-3xl">Choose an option</h5>
				<hr className="border-t border-primary border-dotted mb-12" />
				{templateOptions}
			</main>
		</>
	)
}

export default Doc
