import CopyToClipboard from 'react-copy-to-clipboard'
import Button from './Buttons'
import { FaCheck } from 'react-icons/fa6'
import { MdContentCopy } from 'react-icons/md'
import { useEffect, useState } from 'react'

const CopyButton = ({
	textToCopy,
	disabled,
	iconColor,
	onCopy,
}: {
	textToCopy: string
	disabled: boolean
	iconColor?: string
	onCopy?: () => void
}) => {
	const [copied, setCopied] = useState(false)

	useEffect(() => {
		if (copied) {
			const timeoutId = setTimeout(() => {
				setCopied(false)
			}, 2000)
			return () => clearTimeout(timeoutId)
		}
	}, [copied])

	const handleCopyClick = () => {
		setCopied(true)
		onCopy && onCopy()
	}

	return (
		<CopyToClipboard text={textToCopy} onCopy={handleCopyClick}>
			<Button className="relative h-6 md:mr-4 mt-0.5" type="primary" disabled={disabled}>
				<span>
					<FaCheck
						className={`${iconColor} absolute transition-opacity top-0.5 h-5 left-0 ${
							copied ? 'opacity-100' : 'opacity-0'
						}`}
					/>
					<MdContentCopy
						className={`${iconColor} absolute transition-opacity top-0 h-6 left-0 text-lg ${
							copied ? 'opacity-0' : 'opacity-100'
						}`}
					/>
				</span>
			</Button>
		</CopyToClipboard>
	)
}

export default CopyButton
