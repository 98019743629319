import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { useLocalStorage } from '../helpers/utility'

export const FEATURE_FLAGS = {
	WORD_COUNT: 'word-count-2',
	PHILIPPINES_PRICING: 'philippines-pricing',
	STEALTH_GPT: 'stealth-gpt',
	PADDLE: 'paddle',
	GRADER_V2: 'grader-v2',
	ASK_AI_PROMPT: 'ask-ai-prompt',
	REDESIGNED_SETUP_V2: 'redesigned-setup-v2',
	MONTHLY_SUGGESTION: 'monthly-suggestion',
	THESIS_GENERATION: 'thesis-generation',
	CRISP_CHAT: 'crisp-chat',
	SIMPLIFY_TOPIC: 'simplify-topic',
	COPY_REFERENCES: 'copy-references',
	REDESIGNED_DASHBOARD: 'redesigned-dashboard',
	REFERENCE_HOVER_MENU_V2: 'reference-hover-menu-v2',
	STEPPED_ESSAY_SETUP: 'stepped-essay-setup',
}

export const usePricing = () => {
	const philippinesPricingFF = useFeatureFlagVariantKey(FEATURE_FLAGS.PHILIPPINES_PRICING) as 'control' | '40'

	const defaultPricing = {
		monthly: '9',
		yearly: '6',
		yearlyTotal: '72',
		discount: '33',
		stripePriceId: { yearly: 'price_1Q39I6ApflHFLqu6T9HPheD3', monthly: 'price_1PsqLqApflHFLqu6N1DpjqlU' },
	}
	const philippinesPricing = {
		monthly: {
			control: '400',
			'40': '300',
		},
		yearly: {
			control: '292',
			'40': '233',
		},
		yearlyTotal: {
			control: '3500',
			'40': '2800',
		},
		discount: {
			control: '27',
			'40': '22',
		},
		stripePriceId: {
			yearly: {
				control: 'price_1Pyd4cApflHFLqu6LnNTg4ST',
				'40': 'price_1Pyd4FApflHFLqu6rCWfWwRx',
			},
			monthly: {
				control: 'price_1Pyd2OApflHFLqu6dCeBSFOI',
				'40': 'price_1Pyd15ApflHFLqu6ekx5wHMk',
			},
		},
	}

	if (['control', '40'].includes(philippinesPricingFF as string)) {
		return {
			monthly: philippinesPricing.monthly[philippinesPricingFF] as string,
			yearly: philippinesPricing.yearly[philippinesPricingFF] as string | null,
			yearlyTotal: philippinesPricing.yearlyTotal[philippinesPricingFF] as string | null,
			discount: philippinesPricing.discount[philippinesPricingFF] as string | null,
			stripePriceId: {
				yearly: philippinesPricing.stripePriceId.yearly[philippinesPricingFF] as string | null,
				monthly: philippinesPricing.stripePriceId.monthly[philippinesPricingFF] as string,
			},
		}
	}

	return defaultPricing
}

export const useFreeWordLimit = () => {
	const variant = useFeatureFlagVariantKey(FEATURE_FLAGS.WORD_COUNT)

	const variantToLimit = {
		control: 250,
		'150': 150,
		'350': 350,
	}

	// @ts-ignore
	return variantToLimit[variant ?? 'control'] as 150 | 250 | 350
}

export const useAnnualPlan = () => {
	const [annualPlan, setAnnualPlan] = useLocalStorage('annualPlan', false)

	return [annualPlan, setAnnualPlan] as const
}
