import Footer from '../Components/Footer'
import LoginContent from '../Components/Modals/Login/Login'
import { useSelector } from 'react-redux'
import { selectUser } from '../redux/systemSlice'
import { useEffect, useState } from 'react'
import Loader from '../Components/Loader'
import { useAuth } from '../contexts/Auth'
import { useToken } from '../hooks/token'
import * as Sentry from '@sentry/react'
import useIsPremium from '../hooks/useIsPremium'

function Billing() {
	const user = useSelector(selectUser)
	const { currentUser } = useAuth() as any
	const [loading, setLoading] = useState(false)
	const userToken = useToken()
	const isPremium = useIsPremium()

	function createPortalSession() {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${userToken}` : '',
			},
			body: JSON.stringify({
				userId: user.id,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/payments/portal-session/', requestOptions)
			.then((res) => res.json())
			.then((response: { url: string }) => {
				if (response.url) {
					window.open(response.url, '_self')
				}
			})
			.catch((err) => Sentry.captureException(err))
	}

	useEffect(() => {
		if (isPremium && user.uid) {
			setLoading(true)
			createPortalSession()
		}
	}, [user])

	if (loading) {
		return (
			<div className="flex flex-col justify-center items-center h-screen w-full">
				<Loader />
			</div>
		)
	}
	return (
		<main>
			<div className="flex flex-col justify-center items-center h-full w-full py-24">
				<LoginContent subText="Log in to manage billing." />
			</div>
			<Footer />
		</main>
	)
}

export default Billing
