import { useRef, useEffect, RefObject, useCallback } from 'react'
import './_Dropdown.scss'

type DropdownProps = {
	open: boolean
	setOpen: (val: boolean) => void
	body: JSX.Element
	closeButton: RefObject<HTMLDivElement | HTMLButtonElement>
	className?: string
}

function Dropdown(props: DropdownProps) {
	const dropdownRef = useRef<HTMLDivElement>(null)

	const handleClickOutside = useCallback(
		async (event: any) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target) &&
				props.closeButton.current &&
				!props.closeButton.current.contains(event.target)
			) {
				props.setOpen(false)
			}
		},
		[dropdownRef, props]
	)

	//Close dropdown when user clicks outside of it, ignore outside clicks to the close button as it will handle close itself
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [handleClickOutside])

	return (
		<>
			{props.open && (
				<div ref={dropdownRef} className={'dropdown'}>
					<div className={props.className}>{props.body}</div>
				</div>
			)}
		</>
	)
}

export default Dropdown
