import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

const TypingExtension = Extension.create({
	name: 'typingExtension',

	addOptions() {
		return {
			onUserType: () => {},
		}
	},

	addProseMirrorPlugins() {
		return [
			new Plugin({
				key: new PluginKey('typingExtension'),
				props: {
					handleTextInput: (view, from, to, text) => {
						// Call the function passed via options
						this.options.onUserType(text)

						return false
					},
				},
			}),
		]
	},
})

export default TypingExtension
