import { Redirect, Route } from 'react-router-dom'
import { useAuth } from '../contexts/Auth'
import { useIsAuthenticated } from '../hooks/authenticated'

function PrivateRoute({ component: Component, loginRequired, ...rest }) {
	const authenticated = useIsAuthenticated()
	const { currentUser } = useAuth()

	return (
		<Route
			{...rest}
			render={(props) => {
				return (currentUser && authenticated) || (!loginRequired && authenticated) ? (
					<Component {...props} />
				) : (
					<Redirect to="/" />
				)
			}}
		></Route>
	)
}

export default PrivateRoute
