const ScoreBar = ({ score, fullWidth = false }: { score: number; fullWidth?: boolean }) => {
	return (
		<div className={`h-1 rounded ${fullWidth ? 'w-full' : 'w-28'} border border-gray-400`}>
			<div
				className={`${
					score < 7.5 ? (score < 5 ? 'bg-danger-default' : 'bg-warning-default') : 'bg-success-default'
				} h-full w-[${score * 10}%]`}
				style={{ width: `${score * 10}%` }}
			/>
		</div>
	)
}

export default ScoreBar
