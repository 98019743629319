import { usePostHog } from 'posthog-js/react'
import { useState } from 'react'
import { useAuth } from '../../../contexts/Auth'
import Button from '../../Buttons'
import * as Sentry from '@sentry/react'
import { signInWithGooglePopup } from '../../../firebase'
import ForgotPassword from './ForgotPassword'
import Input from '../../Input'

const errorToText = {
	'invalid-email': 'Please enter a valid email.',
	'invalid-password': 'Password must be at least 6 characters.',
	'wrong-password': 'Invalid password',
	unknown: 'Unknown error, please try again.',
}

const LoginContent = ({
	setModalOpen,
	premiumPath = false,
	setGoToCheckout,
	setVariant,
	subText = 'Log in to continue writing.',
}: {
	setModalOpen?: (open: boolean) => void
	premiumPath?: boolean
	setGoToCheckout?: (humanizerUpgrade: boolean) => void
	setVariant?: (variant: 'login' | 'signup') => void
	subText?: string
}) => {
	const [emailInput, setEmailInput] = useState<string | undefined>('')
	const [passwordInput, setPasswordInput] = useState<string | undefined>('')
	const [forgotPassword, setForgotPassword] = useState(false)
	const [error, setError] = useState<undefined | 'invalid-email' | 'invalid-password' | 'wrong-password' | 'unknown'>(
		undefined
	)
	const { signin, setCurrentUser } = useAuth() as any
	const posthog = usePostHog()

	async function submitForm() {
		const formattedEmailInput = emailInput?.toLowerCase() ?? ''
		Sentry.setUser({ email: formattedEmailInput })

		try {
			const result = await signin(formattedEmailInput, passwordInput)
			posthog.capture('login', { method: 'email' })

			setCurrentUser(result.user)
			if (premiumPath) {
				setGoToCheckout?.(true)
				return
			}
			setModalOpen?.(false)
		} catch {
			setError('wrong-password')
		}
	}

	const loginGoogleUser = async () => {
		const response = await signInWithGooglePopup()
		if (!response) return

		posthog.capture('login', { method: 'google' })

		setCurrentUser(response.user)

		if (premiumPath) {
			setGoToCheckout?.(true)
		}
		setModalOpen?.(false)
	}

	let modalContent = <></>

	if (forgotPassword) {
		modalContent = <ForgotPassword setForgotPassword={setForgotPassword} />
	} else {
		modalContent = (
			<>
				<div className="flex flex-col gap-2 md:mb-4">
					<h5 className="text-2xl text-center">Welcome Back</h5>
					<p className="text-[16px] text-gray-600 text-center mb-0">{subText}</p>
				</div>
				<div className="flex flex-col gap-9 md:bg-gray-50 pb-5 pt-8 md:px-10 md:border border-gray-400 md:min-w-96 min-w-64">
					<Button
						type="primary"
						className="w-full flex justify-center items-center border px-3 py-3 gap-3 border-gray-500 hover:bg-gray-100 bg-white"
						onClick={loginGoogleUser}
					>
						<div className="gsi-material-button-icon w-5">
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
								<path
									fill="#EA4335"
									d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
								></path>
								<path
									fill="#4285F4"
									d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
								></path>
								<path
									fill="#FBBC05"
									d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
								></path>
								<path
									fill="#34A853"
									d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
								></path>
								<path fill="none" d="M0 0h48v48H0z"></path>
							</svg>
						</div>
						<div className="text-center font-bold">Continue with Google</div>
					</Button>
					<div className="h-0 text-center border border-b-0 border-gray-600">
						<p className="text-sm text-gray-700 relative bottom-[11px] ">
							<span className="bg-gray-50 px-2">Or continue with</span>
						</p>
					</div>
					<div className="flex flex-col gap-6">
						<div>
							<label htmlFor="enter-email-addres" className="text-gray-800">
								Email address
							</label>
							<Input
								value={emailInput ?? ''}
								onChange={(e) => {
									setEmailInput(e.target.value)
									setError(undefined)
								}}
								type="email"
								id="enter-email-address"
								placeholder="you@example.com"
							/>
						</div>
						<div>
							<label htmlFor="password" className="text-gray-800">
								Password
							</label>
							<Input
								value={passwordInput ?? ''}
								onChange={(e) => {
									setPasswordInput(e.target.value)
									setError(undefined)
								}}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										submitForm()
									}
								}}
								type="password"
								id="password"
								placeholder="Password"
							/>
						</div>
						{error && <div className="error">{errorToText[error]}</div>}
						<div className="flex flex-col gap-2">
							{
								<Button
									type="tertiary"
									onClick={submitForm}
									emphasize
									className="w-full h-10 font-bold my-2"
									disabled={!emailInput || !passwordInput || passwordInput.length < 6 || emailInput.length === 0}
								>
									<span>Login</span>
								</Button>
							}
							<div
								className="text-link text-sm cursor-pointer"
								onClick={() => {
									setForgotPassword(true)
								}}
							>
								Forgot password
							</div>
							<p
								className="text-sm text-gray-600 mb-0"
								onClick={() => {
									setVariant && setVariant('signup')
								}}
							>
								Don't have an account? <span className="text-link cursor-pointer">Sign up here.</span>
							</p>
						</div>
					</div>
				</div>
			</>
		)
	}
	return <>{modalContent}</>
}

export default LoginContent
