import './_Thanks.scss'
import { useEffect, useMemo, useState } from 'react'
import { useAnnualPlan, usePricing } from '../../hooks/featureFlags'
import Modal from './Modal'
import { usePostHog } from 'posthog-js/react'
import { useDispatch } from 'react-redux'
import { useLocalStorage } from '../../helpers/utility'
import { TbConfetti } from 'react-icons/tb'
import Button from '../Buttons'

function Thanks() {
	const [thanksModalOpen, setThanksModalOpen] = useState(false)
	const [pageRefreshed, setPageRefreshed] = useLocalStorage('succeeessssfulPaymentReloads', false)
	const dispatch = useDispatch()
	const posthog = usePostHog()
	const price = usePricing()
	const searchParamsString = document.location.search
	const [annualPlan] = useAnnualPlan()
	const searchParams = useMemo(() => {
		let params = new URLSearchParams(searchParamsString)

		return {
			showModal: params.getAll('showModal')[0],
			paddleSuccess: params.getAll('paddleSuccess')[0],
			annual: params.getAll('annual')[0],
		}
	}, [searchParamsString])

	const capturePurchase = () => {
		posthog?.capture('purchase', {
			price: annualPlan ? price.yearlyTotal : price.monthly,
			plan: annualPlan ? 'annual' : 'monthly',
		})
	}

	useEffect(() => {
		if (searchParams.showModal) {
			setThanksModalOpen(true)
			const newSearchParams = new URLSearchParams(searchParamsString)
			newSearchParams.delete('showModal')
			const newUrl = window.location.pathname + (newSearchParams.toString() ? '?' + newSearchParams.toString() : '')
			window.history.replaceState(null, '', newUrl)
			capturePurchase()
		}
	}, [searchParams, dispatch])

	useEffect(() => {
		if (searchParams.paddleSuccess && pageRefreshed) {
			setThanksModalOpen(true)
			const newSearchParams = new URLSearchParams(searchParamsString)
			newSearchParams.delete('paddleSuccess')
			const newUrl = window.location.pathname + (newSearchParams.toString() ? '?' + newSearchParams.toString() : '')
			window.history.replaceState(null, '', newUrl)
			capturePurchase()
		}
	}, [searchParams, dispatch])

	useEffect(() => {
		if (searchParams.paddleSuccess && !pageRefreshed) {
			setPageRefreshed(true)
			window.location.reload()
		}
	}, [searchParams, pageRefreshed])

	return (
		<Modal
			open={thanksModalOpen}
			closeModal={() => {
				setThanksModalOpen(false)
			}}
			portalClassName="thanks"
		>
			<div className="flex flex-col gap-2 items-center bg-secondary pt-10 pb-6 px-3">
				<div className="w-12 h-12 bg-white rounded-full flex items-center justify-center">
					<TbConfetti className="text-2xl" />
				</div>
				<div className="text-2xl font-semibold text-center">You're now a Premium member!</div>
			</div>
			<div className="flex flex-col gap-2 md:px-10 px-5 py-4">
				<div>
					Enjoy <b>unlimited</b> access to all of our premium features, including:
					<ul className="list-disc list-inside mt-2">
						<li className="text-[16px] mb-0">2500 word essay generations</li>
						<li className="text-[16px] mb-0">Unlimited humanization through our AI detection bypasser</li>
						<li className="text-[16px] mb-0">Unlimited AI detection checks</li>
					</ul>
				</div>
				<Button type="secondary" emphasize className="py-2" onClick={() => setThanksModalOpen(false)}>
					Get Started
				</Button>
			</div>
		</Modal>
	)
}

export default Thanks
